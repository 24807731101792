import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { LanguageContext } from '../../../../lib/i18n/LanguageProvider';
import { Helmet } from 'react-helmet-async';

const IndividualPricing: React.FC = () => {
    const { lang } = useParams();
    const context = useContext(LanguageContext);

    if (!context) {
        return null;
    }

    const { translations } = context;

    const pricingData = [
        {
            title: 'PAKET 100',
            price: '2.520,00 ₺',
            description: '100 Kişilik Organizasyonlar İçin Önerilir',
            features: [
                'Organizasyon Yönetimi Yazılımı',
                '7/24 LCV Karşılama Hizmeti ve Geri Dönüş Yapmayan Davetlilere LCV Hizmeti (60 Adet)',
                'Oturma Planı Düzenleme Yazılımı',
                'Oturma Planını SMS Olarak Katılımcılara Bildirme Hizmeti (60 Adet)',
            ],
        },
        {
            title: 'PAKET 200',
            price: '4.830,00 ₺',
            description: '200 Kişilik Organizasyonlar İçin Önerilir',
            features: [
                'Organizasyon Yönetimi Yazılımı',
                '7/24 LCV Karşılama Hizmeti ve Geri Dönüş Yapmayan Davetlilere LCV Hizmeti (120 Adet)',
                'Oturma Planı Düzenleme Yazılımı',
                'Oturma Planını SMS Olarak Katılımcılara Bildirme Hizmeti (120 Adet)',
            ],
        },
        {
            title: 'PAKET 300',
            price: '6.930,00 ₺',
            description: '300 Kişilik Organizasyonlar İçin Önerilir',
            features: [
                'Organizasyon Yönetimi Yazılımı',
                '7/24 LCV Karşılama Hizmeti ve Geri Dönüş Yapmayan Davetlilere LCV Hizmeti (180 Adet)',
                'Oturma Planı Düzenleme Yazılımı',
                'Oturma Planını SMS Olarak Katılımcılara Bildirme Hizmeti (180 Adet)',
            ],
        },
        {
            title: 'PAKET 400',
            price: '9.660,00 ₺',
            description: '400 Kişilik Organizasyonlar İçin Önerilir',
            features: [
                'Organizasyon Yönetimi Yazılımı',
                '7/24 LCV Karşılama Hizmeti ve Geri Dönüş Yapmayan Davetlilere LCV Hizmeti (240 Adet)',
                'Oturma Planı Düzenleme Yazılımı',
                'Oturma Planını SMS Olarak Katılımcılara Bildirme Hizmeti (240 Adet)',
            ],
        },
        {
            title: 'PAKET 500',
            price: '10.920,00 ₺',
            description: '500 Kişilik Organizasyonlar İçin Önerilir',
            features: [
                'Organizasyon Yönetimi Yazılımı',
                '7/24 LCV Karşılama Hizmeti ve Geri Dönüş Yapmayan Davetlilere LCV Hizmeti (300 Adet)',
                'Oturma Planı Düzenleme Yazılımı',
                'Oturma Planını SMS Olarak Katılımcılara Bildirme Hizmeti (300 Adet)',
            ],
        },
        {
            title: 'PAKET 750',
            price: '13.230,00 ₺',
            description: '750 Kişilik Organizasyonlar İçin Önerilir',
            features: [
                'Organizasyon Yönetimi Yazılımı',
                '7/24 LCV Karşılama Hizmeti ve Geri Dönüş Yapmayan Davetlilere LCV Hizmeti (420 Adet)',
                'Oturma Planı Düzenleme Yazılımı',
                'Oturma Planını SMS Olarak Katılımcılara Bildirme Hizmeti (420 Adet)',
            ],
        },
        {
            title: 'PAKET 1000',
            price: '16.030,00 ₺',
            description: '1000 Kişilik Organizasyonlar İçin Önerilir',
            features: [
                'Organizasyon Yönetimi Yazılımı',
                '7/24 LCV Karşılama Hizmeti ve Geri Dönüş Yapmayan Davetlilere LCV Hizmeti (600 Adet)',
                'Oturma Planı Düzenleme Yazılımı',
                'Oturma Planını SMS Olarak Katılımcılara Bildirme Hizmeti (600 Adet)',
            ],
        },
        {
            title: 'PAKET 1250',
            price: '18.760,00 ₺',
            description: '1250 Kişilik Organizasyonlar İçin Önerilir',
            features: [
                'Organizasyon Yönetimi Yazılımı',
                '7/24 LCV Karşılama Hizmeti ve Geri Dönüş Yapmayan Davetlilere LCV Hizmeti (750 Adet)',
                'Oturma Planı Düzenleme Yazılımı',
                'Oturma Planını SMS Olarak Katılımcılara Bildirme Hizmeti (750 Adet)',
            ],
        },
        {
            title: 'PAKET 1500',
            price: '21.500,00 ₺',
            description: '1500 Kişilik Organizasyonlar İçin Önerilir',
            features: [
                'Organizasyon Yönetimi Yazılımı',
                '7/24 LCV Karşılama Hizmeti ve Geri Dönüş Yapmayan Davetlilere LCV Hizmeti (900 Adet)',
                'Oturma Planı Düzenleme Yazılımı',
                'Oturma Planını SMS Olarak Katılımcılara Bildirme Hizmeti (900 Adet)',
            ],
        },
    ];

    return (
        <div>
            <Helmet>
                <title>{translations['individual_pricing'] ? `${translations['individual_pricing']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">
                <div className="section-padding bg-secondary">
                    <div className="container">
                        <h2 className="text-center text-white mb-5">Bireysel Üyelik Ücretleri</h2>
                        <div className="row">
                            {pricingData.map((packageData, index) => (
                                <div className="col-lg-4 mb-4" key={index}>
                                    <div className="card pricing-card">
                                        <div className="card-body text-center">
                                            <h3 className="card-title">{packageData.title}</h3>
                                            <p className="card-price text-primary"><h6>{packageData.price}</h6></p>
                                            <p className="card-description">{packageData.description}</p>
                                            <ul className="list-unstyled">
                                                {packageData.features.map((feature, idx) => (
                                                    <li key={idx} className="mb-2">
                                                        {feature}
                                                    </li>
                                                ))}
                                            </ul>
                                            {/* <button className="btn btn-primary mt-3">Satın Al</button> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndividualPricing;
