import { Dropdown } from 'react-bootstrap';
import { FaGlobe } from 'react-icons/fa';
import { useLanguage } from './LanguageProvider';
import { useNavigate, useLocation } from 'react-router-dom';

const supportedLanguages = [
  { code: 'en-US', name: 'English' },
  { code: 'tr-TR', name: 'Türkçe' },
  { code: 'az-AZ', name: 'Azərbaycan dili' },
  { code: 'ru-RU', name: 'Русский' }
] as const;

type SupportedLanguageCode = typeof supportedLanguages[number]['code'];

const languageLabels: Record<SupportedLanguageCode, string> = {
  'en-US': 'Language',
  'tr-TR': 'Dil',
  'az-AZ': 'Dil',
  'ru-RU': 'Язык'
};

function LanguageSelector() {
  const context = useLanguage();
  const navigate = useNavigate();
  const location = useLocation(); // Mevcut URL bilgilerini almak için

  if (!context) {
    return null;
  }

  const { setLang, lang } = context;

  const changeLanguage = (newLang: SupportedLanguageCode) => {
    setLang(newLang); // translations'ı güncelle

    // Mevcut URL'den path'i al ve dili değiştirerek yönlendir
    const currentPath = location.pathname;
    const newPath = currentPath.replace(`/${lang}`, `/${newLang}`); // Eski dili yeni dil ile değiştir

    navigate(newPath); // Yeni path ile yönlendir
  };

  return (
    <Dropdown>
      {/* Dropdown Trigger */}
      <Dropdown.Toggle className="btn btn-primary btn-sm rounded-pill m-1">
        <FaGlobe /> {languageLabels[lang] || 'Language'}
      </Dropdown.Toggle>

      {/* Dropdown Menu */}
      <Dropdown.Menu align="end">
        {supportedLanguages.map((language) => (
          <Dropdown.Item
            key={language.code}
            onClick={() => changeLanguage(language.code)}
          >
            {language.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LanguageSelector;
