// LangStore.tsx
const LANGUAGE_KEY = 'selectedLanguage';

// Dili `localStorage`'a kaydet
export const setStorageLang = (language: string) => {
    localStorage.setItem(LANGUAGE_KEY, language);
};

// `localStorage`'dan dili oku
export const getStorageLang = () => {
    return localStorage.getItem(LANGUAGE_KEY);
};
