import { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaSignInAlt } from 'react-icons/fa';
import { LanguageContext } from '../lib/i18n/LanguageProvider';
import { useParams } from 'react-router-dom';

function LoginDropdown() {
  const { lang } = useParams();
  const context = useContext(LanguageContext);

  if (!context) {
    return null; // Dil bağlamı yüklenmemişse hiçbir şey döndürme
  }

  const { translations } = context;

  return (
    <Dropdown>
      {/* Dropdown Tetikleyici */}
      <Dropdown.Toggle
        id="dropdown-custom"
        className="btn btn-primary btn-sm rounded-pill"
        variant="primary"
      >
        <span className="btn-inner">
          <FaSignInAlt /> {translations['login']}
        </span>
      </Dropdown.Toggle>

      {/* Dropdown Menü */}
      <Dropdown.Menu align="end">
        <Dropdown.Item href={`/${lang}/individual-login`}>{translations['individual_login']}</Dropdown.Item>
        <Dropdown.Item href={`/${lang}/corporate-login`}>{translations['corporate_login']}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LoginDropdown;
