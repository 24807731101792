import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { LanguageContext } from '../../../../lib/i18n/LanguageProvider';
import { useParams } from 'react-router-dom';

import topHeaderImage from '../../../../assets/images/banners/top_header.webp';
import memorybook_1 from '../../../../assets/images/banners/memorybook_1.png';

import { FaCheck } from 'react-icons/fa';

const MemoryBook: React.FC = () => {
    const { lang } = useParams();
    const context = useContext(LanguageContext);

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['service_memorybook'] ? `${translations['service_memorybook']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">

                <div
                    className="sub-header"
                    style={{
                        backgroundImage: `url(${topHeaderImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className="text-white mb-4">{translations['service_memorybook']}</h3>
                                <p>{translations['memorybook_description']}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 mb-4 mb-md-0">
                                <h2 className="mb-5">
                                    {translations['memorybook_title_pre_primary']}
                                    <span className="text-primary">
                                        {translations['memorybook_title_primary']}
                                    </span>{' '}
                                    {translations['memorybook_title_post_primary']}
                                </h2>
                                <p className="text-start text-black">
                                    {translations['memorybook_long_description']}
                                </p>
                                <div className="row row-cols-1">
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['memorybook_easy_access']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['memorybook_easy_access_desc']}
                                        </p>
                                    </div>

                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['memorybook_instant_message']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['memorybook_instant_message_desc']}
                                        </p>
                                    </div>

                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['memorybook_photo_upload']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['memorybook_photo_upload_desc']}
                                        </p>
                                    </div>

                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['memorybook_portal_reporting']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['memorybook_portal_reporting_desc']}
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={memorybook_1} alt="Banner" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default MemoryBook;
