import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { LanguageContext } from '../../../../lib/i18n/LanguageProvider';
import { useParams } from 'react-router-dom';

import topHeaderImage from '../../../../assets/images/banners/top_header.webp';
import rsvpviasms_1 from '../../../../assets/images/banners/rsvpviasms_1.png';

import { FaBuilding, FaCheck, FaEnvelopeOpenText, FaFile, FaLink } from 'react-icons/fa';

const RSVPviaSMS: React.FC = () => {
    const { lang } = useParams();
    const context = useContext(LanguageContext);

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['smsrsvp_service'] ? `${translations['smsrsvp_service']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">

                <div
                    className="sub-header"
                    style={{
                        backgroundImage: `url(${topHeaderImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className="text-white mb-4">{translations['smsrsvp_service']}</h3>
                                <p>{translations['smsrsvp_description']}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 mb-4 mb-md-0">
                                <h2 className="mb-5">
                                    {translations['smsrsvp_title_pre_primary']}
                                    {' '}
                                    <span className="text-primary">
                                        {translations['smsrsvp_title_primary']}
                                    </span>
                                    {' '}
                                    {translations['smsrsvp_title_post_primary']}
                                </h2>
                                <p className="text-start text-black">
                                    {translations['smsrsvp_short_description']}
                                </p>
                                <div className="row row-cols-1">
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['smsrsvp_corporate_and_individual_use']}</h6>
                                        </div>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['smsrsvp_30_percent_response_rate']}</h6>
                                        </div>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['smsrsvp_corporate_sms_header']}</h6>
                                        </div>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['smsrsvp_instant_feedback']}</h6>
                                        </div>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['smsrsvp_custom_event_link']}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={rsvpviasms_1} alt="Banner" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding bg-body">
                    <div className="container">
                        <div className="row align-items-center mb-4 mb-lg-5">
                            <div className="col-lg-8">
                                <div className="mb-2 text-uppercase text-primary sub-title">
                                    {translations['customer_service_at_your_fingertips_pretitle']}
                                </div>
                                <h2 className="mb-5">
                                    {translations['smsrsvp_benefits_pre_primary']}
                                    {' '}
                                    <span className="text-primary">
                                        {translations['smsrsvp_benefits_primary']}
                                    </span>
                                    {' '}
                                    {translations['smsrsvp_benefits_post_primary']}
                                </h2>
                            </div>
                        </div>
                        <div className="row align-items-center pt-4 pt-lg-5">
                            <div className="col-12">
                                <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2">
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaBuilding size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">{translations['smsrsvp_corporate_sms_header']}</h6>
                                                <p className="mb-4">{translations['smsrsvp_corporate_sms_header_desc']}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaEnvelopeOpenText size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">{translations['smsrsvp_invite_via_sms']}</h6>
                                                <p className="mb-4">{translations['smsrsvp_invite_via_sms_desc']}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaLink size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">{translations['smsrsvp_send_link_via_sms']}</h6>
                                                <p className="mb-4">{translations['smsrsvp_send_link_via_sms_desc']}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaFile size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">{translations['smsrsvp_reportable_portal']}</h6>
                                                <p className="mb-4">{translations['smsrsvp_reportable_portal_desc']}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default RSVPviaSMS;
