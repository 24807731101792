import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { LanguageContext } from '../../../../lib/i18n/LanguageProvider';
import { useParams } from 'react-router-dom';

import topHeaderImage from '../../../../assets/images/banners/top_header.webp';
import onlineinvitation_1 from '../../../../assets/images/banners/onlineinvitation_1.png';
import onlineinvitation_2 from '../../../../assets/images/banners/onlineinvitation_2.png';
import onlineinvitation_3 from '../../../../assets/images/banners/onlineinvitation_3.png';
import onlineinvitation_4 from '../../../../assets/images/banners/onlineinvitation_4.png';

import { FaCalendarCheck, FaCheck, FaImage, FaLink, FaQrcode, FaSms, FaVideo } from 'react-icons/fa';

const OnlineInvitation: React.FC = () => {
    const { lang } = useParams();
    const context = useContext(LanguageContext);

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['online_invitation_rsvp'] ? `${translations['online_invitation_rsvp']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">

                <div
                    className="sub-header"
                    style={{
                        backgroundImage: `url(${topHeaderImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className="text-white mb-4">{translations['online_invitation_rsvp']}</h3>
                                <p>{translations['online_invitation_description']}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <img src={onlineinvitation_1} alt="Banner" className="img-fluid" />
                            </div>
                            <div className="col-md-6 mb-4 mb-md-0">
                                <h2 className="mb-5">
                                    {translations['online_invitation_title_pre_primary']}
                                    {' '}
                                    <span className="text-primary">
                                        {translations['online_invitation_title_primary']}
                                    </span>
                                    {' '}
                                    {translations['online_invitation_title_post_primary']}
                                </h2>
                                <div className="row row-cols-1">
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['online_invitation_rsvp_module']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['online_invitation_rsvp_module_desc']}
                                        </p>
                                    </div>

                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['online_invitation_sms_whatsapp']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['online_invitation_sms_whatsapp_desc']}
                                        </p>
                                    </div>

                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['online_invitation_special_link']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['online_invitation_special_link_desc']}
                                        </p>
                                    </div>

                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['online_invitation_reporting']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['online_invitation_reporting_desc']}
                                        </p>
                                    </div>

                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['online_invitation_notes']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['online_invitation_notes_desc']}
                                        </p>
                                    </div>

                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['online_invitation_personal_invitation']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['online_invitation_personal_invitation_desc']}
                                        </p>
                                    </div>

                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['online_invitation_fast_mass']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['online_invitation_fast_mass_desc']}
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding bg-body">
                    <div className="container">
                        <div className="row align-items-center mb-4 mb-lg-5">
                            <div className="col-lg-6">
                                <div className="mb-2 text-primary sub-title">
                                    {translations['online_invitation_customer_service']}
                                </div>
                                <h2 className="mb-0">
                                    {translations['what_is_online_invitation_pre_primary']}
                                    {' '}
                                    <span className="text-primary">{translations['what_is_online_invitation_primary']}</span>
                                    {' '}
                                    {translations['what_is_online_invitation_post_primary']}
                                </h2>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb-2">
                                    {translations['online_invitation_what_is_it_desc']}
                                </p>
                                <a
                                    href={`/${lang}/individual-register`}
                                    rel="noopener noreferrer"
                                    className="btn btn-primary mt-4 mt-lg-5 mb-5 mb-lg-0"
                                >
                                    {translations['online_invitation_start_now']}
                                </a>
                            </div>
                        </div>
                        <div className="row align-items-center pt-4 pt-lg-5">
                            <div className="col-md-8">
                                <img
                                    src={onlineinvitation_2}
                                    alt="Banner Left"
                                    className="img-fluid mb-4 mb-lg-0"
                                />
                            </div>
                            <div className="col-md-4">
                                <img
                                    src={onlineinvitation_3}
                                    alt="Banner Right"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                        <div className="row align-items-center pt-4 pt-lg-5">
                            <div className="col-12">
                                <div className="row row-cols-2 row-cols-lg-3 row-cols-md-4">
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaVideo size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">
                                                    {translations['online_invitation_video_invitation']}
                                                </h6>
                                                <p className="mb-4">
                                                    {translations['online_invitation_video_invitation_desc']}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaSms size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">
                                                    {translations['online_invitation_sms_invitation']}
                                                </h6>
                                                <p className="mb-4">
                                                    {translations['online_invitation_sms_invitation_desc']}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaQrcode size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">
                                                    {translations['online_invitation_qr_invitation']}
                                                </h6>
                                                <p className="mb-4">
                                                    {translations['online_invitation_qr_invitation_desc']}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaCalendarCheck size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">
                                                    {translations['online_invitation_save_the_date']}
                                                </h6>
                                                <p className="mb-4">
                                                    {translations['online_invitation_save_the_date_desc']}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaLink size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">
                                                    {translations['online_invitation_with_language_support']}
                                                </h6>
                                                <p className="mb-4">
                                                    {translations['online_invitation_with_language_support_desc']}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaImage size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">
                                                    {translations['online_invitation_image_share']}
                                                </h6>
                                                <p className="mb-4">
                                                    {translations['online_invitation_image_share_desc']}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <img src={onlineinvitation_4} alt="Banner" className="img-fluid" />
                            </div>
                            <div className="col-md-6 mb-4 mb-md-0">
                                <h2 className="mb-5">
                                    {translations['features_of_online_invitation_pre_primary']}
                                    {' '}
                                    <span className="text-primary">{translations['features_of_online_invitation_primary']}</span>
                                    {' '}
                                    {translations['features_of_online_invitation_post_primary']}
                                </h2>
                                <div className="row row-cols-1">
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['online_invitation_corporate_and_individual_use']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['online_invitation_corporate_and_individual_use_desc']}
                                        </p>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['online_invitation_real_time_guest_tracking']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['online_invitation_real_time_guest_tracking_desc']}
                                        </p>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['online_invitation_worldwide_247_rsvp']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['online_invitation_worldwide_247_rsvp_desc']}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnlineInvitation;
