import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LanguageContext } from '../../../../lib/i18n/LanguageProvider';
import { Helmet } from 'react-helmet-async';
import { FaFacebook, FaGoogle, FaInstagram, FaLinkedinIn, FaUser, FaUserPlus } from 'react-icons/fa';

const CorporateLogin: React.FC = () => {
    const { lang } = useParams();
    const context = useContext(LanguageContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('Email:', email, 'Password:', password, 'Remember Me:', rememberMe);
    };

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['corporate_login'] ? `${translations['corporate_login']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">
                <div className="section-padding bg-secondary">
                    <div className="container-inside">
                        <div className="main-circle circle-small"></div>
                        <div className="main-circle circle-medium"></div>
                        <div className="main-circle circle-large"></div>
                        <div className="main-circle circle-xlarge"></div>
                        <div className="main-circle circle-xxlarge"></div>
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="text-center">Kurumsal Üye Girişi</h3>
                                        <p className="text-center">Bu ekrandan kurumsal hesapların girişi yapılmaktadır.</p>
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="email-id">E-Posta</label>
                                                <input
                                                    type="email"
                                                    className="form-control mb-0"
                                                    id="email-id"
                                                    placeholder="E-posta adresini giriniz."
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="password">Şifre</label>
                                                <input
                                                    type="password"
                                                    className="form-control mb-0"
                                                    id="password"
                                                    placeholder="Şifrenizi giriniz."
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <div className="form-check d-inline-block pt-1 mb-0">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="customCheck11"
                                                        checked={rememberMe}
                                                        onChange={(e) => setRememberMe(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="customCheck11">Beni Hatırla</label>
                                                </div>
                                                <a href={`/${lang}/reset-password`}>Şifremi Unuttum</a>
                                            </div>
                                            <div className="text-center pb-3">
                                                <button type="submit" className="btn btn-primary">Giriş Yap</button>
                                            </div>
                                        </form>
                                        <p className="text-center">
                                            Üyeliğiniz yok mu?
                                            <a href={`/${lang}/corporate-register`} className="ms-2"> Buraya tıklayarak kaydolun.</a>
                                        </p>
                                        <p className="text-center">Ya da bağlı sosyal medya hesaplarınızla giriş yapın.</p>
                                        <div className="d-flex justify-content-center">
                                            <ul className="list-group list-group-horizontal list-group-flush">
                                                <li className="list-group-item border-0 pb-0">
                                                    <a href="#"><FaFacebook size={40} className="mb-4 text-primary" /></a>
                                                </li>
                                                <li className="list-group-item border-0 pb-0">
                                                    <a href="#"><FaGoogle size={40} className="mb-4 text-primary" /></a>
                                                </li>
                                                <li className="list-group-item border-0 pb-0">
                                                    <a href="#"><FaInstagram size={40} className="mb-4 text-primary" /></a>
                                                </li>
                                                <li className="list-group-item border-0 pb-0">
                                                    <a href="#"><FaLinkedinIn size={40} className="mb-4 text-primary" /></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row row-cols-1 row-cols-lg-1 row-cols-md-1">
                                    <a href={`/${lang}/corporate-register`} className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaUserPlus size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">Kurumsal Üye Kaydı</h6>
                                                <p className="mb-4">Eğer bir üyeliğiniz yoksa buraya tıklayarak saniyeler içerisinde hemen kaydolun.</p>

                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="row row-cols-2 row-cols-lg-2 row-cols-md-2">
                                    <a href={`/${lang}/individual-login`} className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaUser size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">Bireysel Üye Girişi</h6>
                                                <p className="mb-4">Eğer üyeğiliniz bireysel ise buraya tıklayarak bireysel üye giriş ekranından girişinizi yapabilirsiniz.</p>

                                            </div>
                                        </div>
                                    </a>
                                    <a href={`/${lang}/individual-register`} className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaUserPlus size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">Bireysel Üye Kaydı</h6>
                                                <p className="mb-4">Eğer bir üyeliğiniz yoksa buraya tıklayarak saniyeler içerisinde hemen kaydolun.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CorporateLogin;
