import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LanguageContext } from '../../../../lib/i18n/LanguageProvider';
import { Helmet } from 'react-helmet-async';
import { FaUser, FaUserPlus, FaSignInAlt, FaUsers } from 'react-icons/fa';

const ResetPassword: React.FC = () => {
    const { lang } = useParams();
    const context = useContext(LanguageContext);

    const [email, setEmail] = useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('Email:', email);
        // Şifre sıfırlama e-posta işlemi burada yapılacak
    };

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['reset_password'] ? `${translations['reset_password']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">
                <div className="section-padding bg-secondary">
                    <div className="container-inside">
                        <div className="main-circle circle-small"></div>
                        <div className="main-circle circle-medium"></div>
                        <div className="main-circle circle-large"></div>
                        <div className="main-circle circle-xlarge"></div>
                        <div className="main-circle circle-xxlarge"></div>
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="text-center">Şifre Sıfırlama</h3>
                                        <p className="text-center">Lütfen şifrenizi sıfırlamak için e-posta adresinizi giriniz.</p>
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="email-id">E-Posta Adresi</label>
                                                <input
                                                    type="email"
                                                    className="form-control mb-0"
                                                    id="email-id"
                                                    placeholder="E-posta adresinizi giriniz."
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className="text-center pb-3">
                                                <button type="submit" className="btn btn-primary">Şifre Sıfırlama E-Postası Gönder</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row row-cols-2 row-cols-lg-2 row-cols-md-2">
                                    <a href={`/${lang}/individual-login`} className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaSignInAlt size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">Bireysel Üye Girişi</h6>
                                                <p className="mb-4">Bireysel üyelik şifrenizi hatırlıyorsanız giriş yapmak için tıklayın.</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href={`/${lang}/individual-register`} className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaUserPlus size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">Bireysel Üye Kaydı</h6>
                                                <p className="mb-4">Yeni bir bireysel hesap oluşturmak için hemen tıklayın.</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href={`/${lang}/corporate-login`} className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaUsers size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">Kurumsal Üye Girişi</h6>
                                                <p className="mb-4">Kurumsal üyelik şifrenizi hatırlıyorsanız giriş yapmak için tıklayın.</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href={`/${lang}/corporate-register`} className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaUserPlus size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">Kurumsal Üye Kaydı</h6>
                                                <p className="mb-4">Yeni bir kurumsal hesap hesap oluşturmak için tıklayın.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
