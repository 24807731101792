import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { LanguageContext } from '../../../../lib/i18n/LanguageProvider';

import topHeaderImage from '../../../../assets/images/banners/top_header.webp';
import rsvpservice_1 from '../../../../assets/images/banners/rsvpservice_1.png';
import rsvpservice_2 from '../../../../assets/images/banners/rsvpservice_2.png';

import { FaCheck, FaClipboardCheck, FaClock, FaHeadphonesAlt, FaUserFriends } from 'react-icons/fa';

const RSVPService: React.FC = () => {
    const context = useContext(LanguageContext);

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['rsvpservice_service'] ? `${translations['rsvpservice_service']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">

                <div
                    className="sub-header"
                    style={{
                        backgroundImage: `url(${topHeaderImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className="text-white mb-4">{translations['rsvpservice_service']}</h3>
                                <p>{translations['rsvpservice_description_1']}</p>
                                <p>{translations['rsvpservice_description_2']}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding bg-body">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 mb-4 mb-md-0">
                                <div className="mb-2 text-uppercase text-primary sub-title">
                                    {translations['rsvpservice_why_techrsvp']}
                                </div>
                                <h2 className="mb-5">
                                    {translations['rsvpservice_how_we_provide_pre_primary']}
                                    {' '}
                                    <span className="text-primary">
                                        {translations['rsvpservice_how_we_provide_primary']}
                                    </span>
                                    {' '}
                                    {translations['rsvpservice_how_we_provide_post_primary']}
                                </h2>
                                <p className="mb-5 text-black">
                                    {translations['rsvpservice_long_description']}
                                </p>
                                <div className="row row-cols-1">
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['rsvpservice_inbound_calls']}</h6>
                                        </div>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['rsvpservice_outbound_calls']}</h6>
                                        </div>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['rsvpservice_real_time_tracking']}</h6>
                                        </div>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['rsvpservice_online_reports']}</h6>
                                        </div>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['rsvpservice_final_participant_count']}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={rsvpservice_1} alt="Banner" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding bg-body">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <div className="mb-3 text-uppercase text-primary sub-title">
                                    {translations['rsvpservice_not_just_call_center_title']}
                                </div>
                                <h3 className="mb-5 text-secondary">
                                    {translations['rsvpservice_not_just_call_center_pre_primary']}
                                    {' '}
                                    <span className="text-primary">
                                        {translations['rsvpservice_not_just_call_center_primary']}
                                    </span>
                                    {' '}
                                    {translations['rsvpservice_not_just_call_center_post_primary']} 
                                </h3>
                                <img src={rsvpservice_2} alt="Service" className="img-fluid mt-3" />
                            </div>
                            <div className="col-lg-6">
                                <div className="row row-cols-1 row-cols-lg-2 row-cols-md-2">
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaClipboardCheck size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">{translations['rsvpservice_systematic_operations']}</h6>
                                                <p className="mb-4">{translations['rsvpservice_systematic_operations_desc']}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaUserFriends size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">{translations['rsvpservice_professional_team']}</h6>
                                                <p className="mb-4">{translations['rsvpservice_professional_team_desc']}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaHeadphonesAlt size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">{translations['rsvpservice_call_center']}</h6>
                                                <p className="mb-4">{translations['rsvpservice_call_center_desc']}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaClock size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">{translations['rsvpservice_247_support']}</h6>
                                                <p className="mb-4">{translations['rsvpservice_247_support_desc']}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RSVPService;
