import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { LanguageContext } from '../../../lib/i18n/LanguageProvider';
import CountUp from 'react-countup';

import topHeaderImage from '../../../assets/images/banners/top_header.webp';

import about_us_1 from '../../../assets/images/banners/aboutus_1.png';
import { FaArrowCircleRight, FaArrowRight, FaCheck } from 'react-icons/fa';

const AboutUs: React.FC = () => {
    const context = useContext(LanguageContext);

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['about_us'] ? `${translations['about_us']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">

                <div
                    className="sub-header"
                    style={{
                        backgroundImage: `url(${topHeaderImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className="text-white mb-4">{translations['about_us']}</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row align-items-center mb-4 mb-lg-5">
                            <div className="col-lg-6">
                                <div className="mb-2 text-uppercase text-primary sub-title">
                                    {translations['about_us_what_is']}
                                </div>
                                <h2 className="text-secondary mb-5">
                                    {translations['about_us_pre_techrsvp']}
                                    {' '}
                                    <span className="text-primary">{translations['about_us_techrsvp']}</span>
                                    {' '}
                                    {translations['about_us_post_techrsvp']}
                                </h2>
                            </div>
                            <div className="col-lg-6">
                                <p className="text-start text-black">
                                    {translations['about_us_description1']}
                                </p>
                                <p className="text-start text-black">
                                    {translations['about_us_description2']}
                                </p>
                                <p className="text-start text-black">
                                    {translations['about_us_description3']}
                                </p>
                            </div>
                        </div>
                        <div className="row align-items-center text-center row-cols-1 row-cols-sm-1 row-cols-md-3 row-cols-lg-3 mt-5">
                            <div className="col mb-lg-0 mb-4 border-end">
                                <h2 className="mb-3 counter text-primary">
                                    <CountUp end={1300000} duration={5} />+
                                </h2>
                                <h6 className="mb-0">{translations['about_us_lcv_calls']}</h6>
                            </div>
                            <div className="col mb-lg-0 mb-4 border-end">
                                <h2 className="mb-3 counter text-primary">
                                    <CountUp end={180} duration={5} />+
                                </h2>
                                <h6 className="mb-0">{translations['about_us_full_time_employees']}</h6>
                            </div>
                            <div className="col border-end">
                                <h2 className="mb-3 counter text-primary">
                                    <CountUp end={10000} duration={5} />+
                                </h2>
                                <h6 className="mb-0">{translations['about_us_organized_events']}</h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding bg-body">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 mb-4 mb-md-0">
                                <div className="mb-2 text-uppercase text-primary sub-title">
                                    {translations['about_us_why_teachrsvp']}
                                </div>
                                <h2 className="mb-5">
                                    <span className="text-primary">
                                        {translations['about_us_7_departments']}
                                    </span>{' '}
                                    - {translations['about_us_180_employees']}
                                </h2>
                                <p className="mb-5 text-black">
                                    {translations['about_us_description4']}
                                </p>
                                <div className="row row-cols-1">
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['about_us_facebook_partner']}</h6>
                                        </div>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['about_us_global_support']}</h6>
                                        </div>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['about_us_247_customer_support']}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={about_us_1} alt="Banner" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
