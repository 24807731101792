import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { LanguageContext } from '../../../../lib/i18n/LanguageProvider';
import { useParams } from 'react-router-dom';

import topHeaderImage from '../../../../assets/images/banners/top_header.webp';
import seatingplan_1 from '../../../../assets/images/banners/seatingplan_1.png';

import { FaCheck } from 'react-icons/fa';

const SeatingPlan: React.FC = () => {
    const { lang } = useParams();
    const context = useContext(LanguageContext);

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['seating_plan_service'] ? `${translations['seating_plan_service']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">

                <div
                    className="sub-header"
                    style={{
                        backgroundImage: `url(${topHeaderImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className="text-white mb-4">{translations['seating_plan_service']}</h3>
                                <p>{translations['seating_plan_description']}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <img src={seatingplan_1} alt="Banner" className="img-fluid" />
                            </div>
                            <div className="col-md-6 mb-4 mb-md-0">
                                <h2 className="mb-5">
                                    {translations['seating_plan_pre_primary']}
                                    {' '}
                                    <span className="text-primary">
                                        {translations['seating_plan_primary']}
                                    </span>
                                    {' '}
                                    {translations['seating_plan_post_primary']}
                                </h2>
                                <p className="text-start text-black">
                                    {translations['seating_plan_how_to_create_description']}
                                </p>
                                <div className="row row-cols-1">
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['seating_plan_table_occupancy']}</h6>
                                        </div>
                                        <p className="text-black">{translations['seating_plan_table_occupancy_desc']}</p>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['seating_plan_table_number']}</h6>
                                        </div>
                                        <p className="text-black">{translations['seating_plan_table_number_desc']}</p>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['seating_plan_corporate_header']}</h6>
                                        </div>
                                        <p className="text-black">{translations['seating_plan_corporate_header_desc']}</p>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['seating_plan_prevent_crowding']}</h6>
                                        </div>
                                        <p className="text-black">{translations['seating_plan_prevent_crowding_desc']}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SeatingPlan;
