import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { LanguageContext } from '../../../../lib/i18n/LanguageProvider';
import { useParams } from 'react-router-dom';

import topHeaderImage from '../../../../assets/images/banners/top_header.webp';
import rsvpviawhatsapp_1 from '../../../../assets/images/banners/rsvpviawhatsapp_1.png';

import { FaCalendarCheck, FaCheck, FaImage, FaLink, FaVideo } from 'react-icons/fa';

const RSVPviaWhatsapp: React.FC = () => {
    const { lang } = useParams();
    const context = useContext(LanguageContext);

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['whatsapp_rsvp_service'] ? `${translations['whatsapp_rsvp_service']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">

                <div
                    className="sub-header"
                    style={{
                        backgroundImage: `url(${topHeaderImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className="text-white mb-4">{translations['whatsapp_rsvp_service']}</h3>
                                <p>{translations['whatsapp_rsvp_description']}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <img src={rsvpviawhatsapp_1} alt="Banner" className="img-fluid" />
                            </div>
                            <div className="col-md-6 mb-4 mb-md-0">
                                <h2 className="mb-5">
                                    {translations['whatsapp_rsvp_pre_primary']}
                                    {' '}
                                    <span className="text-primary">
                                        {translations['whatsapp_rsvp_primary']}
                                    </span>
                                    {' '}
                                    {translations['whatsapp_rsvp_post_primary']}
                                </h2>
                                <div className="row row-cols-1">
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['whatsapp_rsvp_fast_invite_distribution']}</h6>
                                        </div>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['whatsapp_rsvp_language_support_for_guests']}</h6>
                                        </div>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['whatsapp_rsvp_40_percent_response_rate']}</h6>
                                        </div>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['whatsapp_rsvp_instant_feedback_tracking']}</h6>
                                        </div>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['whatsapp_rsvp_eco_friendly_tech']}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding bg-body">
                    <div className="container">
                        <div className="row align-items-center mb-4 mb-lg-5">
                            <div className="col-lg-8">
                                <div className="mb-2 text-uppercase text-primary sub-title">
                                    {translations['customer_service_at_your_fingertips_pretitle']}
                                </div>
                                <h2 className="mb-5">
                                    {translations['whatsapp_rsvp_types_pre_primary']}
                                    {' '}
                                    <span className="text-primary">
                                        {translations['whatsapp_rsvp_types_primary']}
                                    </span>
                                    {' '}
                                    {translations['whatsapp_rsvp_types_post_primary']}
                                </h2>
                            </div>
                        </div>
                        <div className="row align-items-center pt-4 pt-lg-5">
                            <div className="col-12">
                                <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2">
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaVideo size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">{translations['whatsapp_rsvp_video_invite']}</h6>
                                                <p className="mb-4">{translations['whatsapp_rsvp_video_invite_desc']}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaImage size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">{translations['whatsapp_rsvp_image_invite']}</h6>
                                                <p className="mb-4">{translations['whatsapp_rsvp_image_invite_desc']}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaCalendarCheck size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">{translations['whatsapp_rsvp_save_the_date']}</h6>
                                                <p className="mb-4">{translations['whatsapp_rsvp_save_the_date_desc']}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaLink size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">{translations['whatsapp_rsvp_only_rsvp_link']}</h6>
                                                <p className="mb-4">{translations['whatsapp_rsvp_only_rsvp_link_desc']}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default RSVPviaWhatsapp;
