import React, { useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../../lib/i18n/LanguageProvider';
import { getStorageLang } from '../../../stores/Language';

const LanguageWrapper = ({ children }: { children: React.ReactNode }) => {
  const { lang } = useParams<{ lang: string }>(); // URL'den dili al
  const navigate = useNavigate();
  const context = useContext(LanguageContext);

  useEffect(() => {
    const storedLang = getStorageLang(); // localStorage'daki dili al

    if (!lang) {
      // URL'de dil parametresi yoksa
      const defaultLang = storedLang;
      navigate(`/${defaultLang}`, { replace: true }); // Kullanıcıyı o dile yönlendir
    } else if (context) {
      // Context varsa, setLang fonksiyonunu çağır
      context.setLang(lang as any); // URL'den gelen dili LanguageContext'e set et
    }
  }, [lang, navigate, context]);

  return <>{children}</>;
};

export default LanguageWrapper;
