import { Navigate, useParams } from "react-router-dom";
import { getStorageLang } from "../../../stores/Language";

// Geçerli diller listesi
const validLanguages = ['tr-TR', 'en-US', 'az-AZ', 'ru-RU'];  // Kabul edilen diller

// Dil parametresini kontrol eden bileşen
const LanguageGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { lang } = useParams<{ lang: string }>(); // URL'den dil parametresini al
    const storedLang = getStorageLang() || 'tr-TR'; // localStorage'dan dil al, yoksa varsayılan 'tr-TR'
  
    // Geçersiz dil parametresi kontrolü
    if (!lang || !validLanguages.includes(lang)) {
      return <Navigate to={`/${storedLang}/PageNotFound`} replace />; // Geçersiz dil varsa kullanıcının diline göre PageNotFound'a yönlendir
    }
  
    return <>{children}</>; // Geçerli dil varsa, alt bileşenleri render et
  };

  export default LanguageGuard;