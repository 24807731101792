import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LanguageContext } from '../../../../lib/i18n/LanguageProvider';
import { Helmet } from 'react-helmet-async';
import { FaFacebook, FaGoogle, FaInstagram, FaLinkedinIn, FaSignInAlt, FaUsers, FaUserPlus, FaUser } from 'react-icons/fa';
import countryCodes from '../../../../assets/countryCodes.json'; // Ülke kodları dosyasını ekledik.
import LoadingButton from '../../../../components/Button';

const CorporateRegister: React.FC = () => {
    const { lang } = useParams();
    const context = useContext(LanguageContext);

    const [companyName, setCompanyName] = useState('');
    const [companyCountry, setCompanyCountry] = useState('');
    const [authorizedFirstName, setAuthorizedFirstName] = useState('');
    const [authorizedLastName, setAuthorizedLastName] = useState('');
    const [email, setEmail] = useState('');
    const [countryCode, setCountryCode] = useState(countryCodes[0].phoneCode); // Varsayılan telefon kodu
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validated, setValidated] = useState(false); // Validation state

    const handleSubmit = (e: React.FormEvent<Element>) => {
        e.preventDefault();
        const form = e.currentTarget as HTMLFormElement;  // Explicitly cast to HTMLFormElement

        if (form.checkValidity() === false || password !== confirmPassword) {
            e.stopPropagation();
        } else {
            console.log('Form Submitted:', {
                companyName, companyCountry, authorizedFirstName, authorizedLastName, email, phone, countryCode, password,
            });
        }

        setValidated(true);
    };


    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['corporate_register'] ? `${translations['corporate_register']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">
                <div className="section-padding bg-secondary">
                    <div className="container-inside">
                        <div className="main-circle circle-small"></div>
                        <div className="main-circle circle-medium"></div>
                        <div className="main-circle circle-large"></div>
                        <div className="main-circle circle-xlarge"></div>
                        <div className="main-circle circle-xxlarge"></div>
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="text-center">Kurumsal Üye Kaydı</h3>
                                        <p className="text-center">Lütfen aşağıdaki formu doldurarak kurumsal hesap oluşturun.</p>
                                        <form className={`needs-validation ${validated ? 'was-validated' : ''}`} noValidate onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="form-group col-6">
                                                    <label className="form-label" htmlFor="company-name">Firma Adı</label>
                                                    <input
                                                        type="text"
                                                        className="form-control mb-0"
                                                        id="company-name"
                                                        placeholder="Firma adınızı giriniz."
                                                        value={companyName}
                                                        onChange={(e) => setCompanyName(e.target.value)}
                                                        required
                                                    />
                                                    <div className="invalid-feedback">Firma adı gerekli.</div>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label className="form-label" htmlFor="company-country">Firma Merkezi</label>
                                                    <input
                                                        type="text"
                                                        className="form-control mb-0"
                                                        id="company-country"
                                                        placeholder="Firma adresini giriniz."
                                                        value={companyCountry}
                                                        onChange={(e) => setCompanyCountry(e.target.value)}
                                                        required
                                                    />
                                                    <div className="invalid-feedback">Firma merkezi gerekli.</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-6">
                                                    <label className="form-label" htmlFor="authorized-first-name">Yetkili Adı</label>
                                                    <input
                                                        type="text"
                                                        className="form-control mb-0"
                                                        id="authorized-first-name"
                                                        placeholder="Yetkili adınızı giriniz."
                                                        value={authorizedFirstName}
                                                        onChange={(e) => setAuthorizedFirstName(e.target.value)}
                                                        required
                                                    />
                                                    <div className="invalid-feedback">Yetkili adı gerekli.</div>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label className="form-label" htmlFor="authorized-last-name">Yetkili Soyadı</label>
                                                    <input
                                                        type="text"
                                                        className="form-control mb-0"
                                                        id="authorized-last-name"
                                                        placeholder="Yetkili soyadınızı giriniz."
                                                        value={authorizedLastName}
                                                        onChange={(e) => setAuthorizedLastName(e.target.value)}
                                                        required
                                                    />
                                                    <div className="invalid-feedback">Yetkili soyadı gerekli.</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <label className="form-label" htmlFor="email-id">E-Posta Adresi</label>
                                                    <input
                                                        type="email"
                                                        className="form-control mb-0"
                                                        id="email-id"
                                                        placeholder="E-posta adresinizi giriniz."
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                    <div className="invalid-feedback">Geçerli bir e-posta adresi giriniz.</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-6">
                                                    <label className="form-label" htmlFor="country-code">Telefon Kodu</label>
                                                    <select
                                                        className="form-control mb-0"
                                                        id="country-code"
                                                        value={countryCode}
                                                        onChange={(e) => setCountryCode(e.target.value)}
                                                        required
                                                    >
                                                        {countryCodes.map((code) => (
                                                            <option key={`${code.phoneCode}-${code.countryName}`} value={code.phoneCode}>
                                                                {code.phoneCode} - {code.countryName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <div className="invalid-feedback">Telefon kodu seçiniz.</div>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label className="form-label" htmlFor="phone">Telefon</label>
                                                    <input
                                                        type="text"
                                                        className="form-control mb-0"
                                                        id="phone"
                                                        placeholder="Telefon numaranızı giriniz."
                                                        value={phone}
                                                        onChange={(e) => {
                                                            const input = e.target.value;
                                                            // Sadece rakamlara izin veren regex
                                                            if (/^\d*$/.test(input)) {
                                                                setPhone(input);
                                                            }
                                                        }}
                                                        required
                                                    />
                                                    <div className="invalid-feedback">Telefon numarası gerekli.</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-6">
                                                    <label className="form-label" htmlFor="password">Şifre</label>
                                                    <input
                                                        type="password"
                                                        className="form-control mb-0"
                                                        id="password"
                                                        placeholder="Şifrenizi giriniz."
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        required
                                                    />
                                                    <div className="invalid-feedback">Şifre gerekli.</div>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label className="form-label" htmlFor="confirm-password">Şifre Tekrarı</label>
                                                    <input
                                                        type="password"
                                                        className={`form-control mb-0 ${password !== confirmPassword ? 'is-invalid' : ''}`}
                                                        id="confirm-password"
                                                        placeholder="Şifrenizi tekrar giriniz."
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                        required
                                                    />
                                                    <div className="invalid-feedback">Şifreler eşleşmiyor!</div>
                                                </div>
                                            </div>
                                            <div className="text-center pb-3">
                                                <LoadingButton
                                                    label="Kaydol"
                                                    onClick={handleSubmit}
                                                    className="btn btn-primary"
                                                    tooltip="Kaydol"
                                                />
                                            </div>
                                        </form>
                                        <p className="text-center">
                                            Zaten bir hesabınız var mı?
                                            <a href={`/${lang}/corporate-login`} className="ms-2"> Buraya tıklayarak giriş yapın.</a>
                                        </p>
                                        <p className="text-center">Ya da bağlı sosyal medya hesaplarınızla kaydolun.</p>
                                        <div className="d-flex justify-content-center">
                                            <ul className="list-group list-group-horizontal list-group-flush">
                                                <li className="list-group-item border-0 pb-0">
                                                    <a href="#"><FaFacebook size={40} className="mb-4 text-primary" /></a>
                                                </li>
                                                <li className="list-group-item border-0 pb-0">
                                                    <a href="#"><FaGoogle size={40} className="mb-4 text-primary" /></a>
                                                </li>
                                                <li className="list-group-item border-0 pb-0">
                                                    <a href="#"><FaInstagram size={40} className="mb-4 text-primary" /></a>
                                                </li>
                                                <li className="list-group-item border-0 pb-0">
                                                    <a href="#"><FaLinkedinIn size={40} className="mb-4 text-primary" /></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row row-cols-1 row-cols-lg-1 row-cols-md-1">
                                    <a href={`/${lang}/corporate-login`} className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaSignInAlt size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">Kurumsal Üye Girişi</h6>
                                                <p className="mb-4">Zaten bir kurumsal hesabınız varsa giriş yapmak için tıklayın.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="row row-cols-2 row-cols-lg-2 row-cols-md-2">
                                    <a href={`/${lang}/individual-login`} className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaUser size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">Bireysel Üye Girişi</h6>
                                                <p className="mb-4">Eğer üyeğiliniz bireysel ise buraya tıklayarak bireysel üye giriş ekranından girişinizi yapabilirsiniz.</p>

                                            </div>
                                        </div>
                                    </a>
                                    <a href={`/${lang}/individual-register`} className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaUserPlus size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">Bireysel Üye Kaydı</h6>
                                                <p className="mb-4">Eğer bir üyeliğiniz yoksa buraya tıklayarak saniyeler içerisinde hemen kaydolun.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CorporateRegister;
