import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { LanguageContext } from '../../../lib/i18n/LanguageProvider';

import topHeaderImage from '../../../assets/images/banners/top_header.webp';

import ref1 from '../../../assets/images/referanslar/referans_1.webp';
import ref2 from '../../../assets/images/referanslar/referans_2.webp';
import ref3 from '../../../assets/images/referanslar/referans_3.webp';
import ref4 from '../../../assets/images/referanslar/referans_4.webp';
import ref5 from '../../../assets/images/referanslar/referans_5.webp';
import ref6 from '../../../assets/images/referanslar/referans_6.webp';
import ref7 from '../../../assets/images/referanslar/referans_7.webp';
import ref8 from '../../../assets/images/referanslar/referans_8.webp';
import ref9 from '../../../assets/images/referanslar/referans_9.webp';
import ref10 from '../../../assets/images/referanslar/referans_10.webp';
import ref11 from '../../../assets/images/referanslar/referans_11.webp';
import ref12 from '../../../assets/images/referanslar/referans_12.webp';

const References: React.FC = () => {
    const context = useContext(LanguageContext);

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['references'] ? `${translations['references']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">

                <div
                    className="sub-header"
                    style={{
                        backgroundImage: `url(${topHeaderImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        // height: '400px'
                    }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className="text-white mb-4">{translations['references']}</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding bg-body">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="mb-2 text-uppercase text-center text-primary sub-title">
                                
                            </div>
                            <h2 className=" text-capitalize mb-4 mb-lg-5 pb-4 pb-lg-5 text-center">
                                <span>{translations['references_our_happy_clients_pre_primary']}</span>
                                {' '}
                                <span className="text-primary">{translations['references_our_happy_clients_primary']}</span>
                                {' '}
                                <span>{translations['references_our_happy_clients_post_primary']}</span>
                            </h2>
                            <div className="row mb-5">
                                <div className="col-xl-2 col-lg-4 col-md-4">
                                    <div className="custom-icon custom-box mx-auto">
                                        <img src={ref1} alt="01" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-4 col-md-4 mt-5 mt-md-0">
                                    <div className="custom-icon mx-auto ">
                                        <img src={ref2} alt="02" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-4 col-md-4 mt-5 mt-md-0">
                                    <div className="custom-icon custom-box mx-auto">
                                        <img src={ref3} alt="03" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-4 col-md-4 mt-5 mt-xl-0">
                                    <div className="custom-icon mx-auto">
                                        <img src={ref4} alt="04" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-4 col-md-4 mt-5 mt-xl-0">
                                    <div className="custom-icon custom-box mx-auto">
                                        <img src={ref5} alt="05" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-4 col-md-4 mt-5 mt-xl-0">
                                    <div className="custom-icon mx-auto">
                                        <img src={ref6} alt="06" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-4 col-md-4">
                                    <div className="custom-icon custom-box mx-auto">
                                        <img src={ref7} alt="07" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-4 col-md-4 mt-5 mt-md-0">
                                    <div className="custom-icon mx-auto ">
                                        <img src={ref8} alt="08" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-4 col-md-4 mt-5 mt-md-0">
                                    <div className="custom-icon custom-box mx-auto">
                                        <img src={ref9} alt="09" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-4 col-md-4 mt-5 mt-xl-0">
                                    <div className="custom-icon mx-auto">
                                        <img src={ref10} alt="10" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-4 col-md-4 mt-5 mt-xl-0">
                                    <div className="custom-icon custom-box mx-auto">
                                        <img src={ref11} alt="11" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-4 col-md-4 mt-5 mt-xl-0">
                                    <div className="custom-icon mx-auto">
                                        <img src={ref12} alt="12" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="text-center pt-5">
                                <a href="https://www.albinasoft.com/tr-TR/Portal/Referanslar" target="blank" className="btn btn-primary text-capitalize">
                                {translations['references_see_all']}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
};

export default References;
