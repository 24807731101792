import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { setStorageLang, getStorageLang } from '../../stores/Language';

// Desteklenen diller ve varsayılan dil
const supportedLanguages = ['en-US', 'tr-TR', 'az-AZ', 'ru-RU'] as const;

type LanguageContextType = {
    translations: Record<string, string>;
    setLang: (lang: typeof supportedLanguages[number]) => void;
    lang: typeof supportedLanguages[number];
};

export const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

type LanguageProviderProps = {
    children: ReactNode;
};

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
    const initialLang = (getStorageLang() as typeof supportedLanguages[number]) ;

    const [lang, setLang] = useState<typeof supportedLanguages[number]>(initialLang);
    const [translations, setTranslations] = useState<Record<string, string>>({});

    // Dil JSON dosyasını yükleme ve çeviri map'ini oluşturma
    const loadTranslations = async (language: string) => {
        try {
            const response = await import(`./locales/${language}.json`);
            const translationsMap: Record<string, string> = {};
            response.default.forEach((item: { Header: string, Content: string }) => {
                translationsMap[item.Header] = item.Content;
            });
            setTranslations(translationsMap);
        } catch (error) {
            console.error(`Dil dosyası yüklenemedi: ${language}`, error);
        }
    };

    // İlk yüklemede seçili dilin çevirilerini yükle
    useEffect(() => {
        loadTranslations(lang);
    }, [lang]);

    // Dil değiştirme - sadece dil gerçekten değiştiyse
    const changeLanguage = (newLang: typeof supportedLanguages[number]) => {
        if (newLang !== lang) {
            setLang(newLang);
            setStorageLang(newLang); // localStorage'a kaydet
            loadTranslations(newLang); // Yeni dilin çevirilerini yükle
        }
    };

    return (
        <LanguageContext.Provider value={{ translations, setLang: changeLanguage, lang }}>
            {children}
        </LanguageContext.Provider>
    );
};

// Dil kullanımı için özel Hook
export const useLanguage = () => useContext(LanguageContext);
