import React from 'react';
import { FaChevronUp } from 'react-icons/fa';

const BackToTop: React.FC = () => {

  // Sayfayı en üste kaydıran fonksiyon
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div id="back-to-top">
      <a
        className="p-0 btn btn-primary btn-icon btn-sm position-fixed top"
        id="top"
        href="#top"
        onClick={scrollToTop}
      >
        <FaChevronUp size={20} color="white"/>
        {' '}
      </a>
    </div>
  );
};

export default BackToTop;
