import React, { useContext, useEffect, useState } from 'react';
import { Outlet, Link, useParams } from 'react-router-dom';
import { LanguageContext } from '../../../lib/i18n/LanguageProvider';

import '../../../assets/css/libs.css';
import '../../../assets/css/swiper-bundle.css';
import '../../../assets/css/hope-ui.css';
import '../../../assets/css/pro.css';
import '../../../assets/css/custom.css';
import '../../../assets/css/rtl.css';
import '../../../assets/css/customizer.css';
import '../../../assets/css/landing-pages.css';

import eksiksiz_qrcode from '../../../assets/images/eksiksiz/eksiksiz_qrcode_4.png';

import BackToTop from '../../../components/BackToTop';
import LanguageSelector from '../../../lib/i18n/LanguageSelector';
import LoginDropdown from '../../../components/LoginDropdown';
import { FaFacebook, FaLinkedin, FaInstagram, FaYoutube, FaChevronDown, FaChevronRight, FaMailBulk, FaPhoneAlt, FaMapMarked, FaCircle } from 'react-icons/fa';

interface LayoutProps {
  children?: React.ReactNode;
  pageTitle?: string | null | undefined;
  pageSummary?: string | null | undefined;
  videoHelpUrl?: string | null | undefined;
  showSummaryOnMobile?: boolean;
  showHeader?: boolean;
}

const Layout: React.FC<LayoutProps> = ( ) => {
  const { lang } = useParams();
  const context = useContext(LanguageContext);
  const [navVisible, setNavVisible] = useState(false);
  const [servicesMenuOpen, setServicesMenuOpen] = useState(false); // State to manage "Hizmetlerimiz" menu
  const currentYear = new Date().getFullYear();


  const toggleNav = () => {
    setNavVisible(!navVisible);
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleServicesMenu = () => {
    if (!isDesktop) {
      setServicesMenuOpen(!servicesMenuOpen);
    }
  };

  if (!context) {
    return <></>;
  }

  const { translations } = context;

  return (
    <div>
      {/* <span className={`screen-darken ${navVisible ? 'active' : ''}`}></span> */}
      <main className="main-content">
        <div className="position-relative">
          <nav className="nav navbar navbar-expand-xl navbar-light iq-navbar header-hover-menu fixed-navbar">
            <div className="container-fluid navbar-inner">
              <div className="d-flex align-items-center justify-content-between w-100 landing-header">
                <div className="d-flex align-items-center d-xl-none">
                  <button
                    data-trigger="navbar_main"
                    className="d-xl-none btn btn-primary btn-icon rounded-pill"
                    type="button"
                    onClick={toggleNav}
                  >
                    <FaChevronRight size={20} />
                  </button>
                  <Link to="/" className="navbar-brand ms-3 d-xl-none">
                    <svg className="icon-30 text-primary" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 108 108">
                      <g transform="translate(0,108) scale(0.1,-0.1)" fill="currentColor" stroke="none">
                        <path d="M397 1045 c-138 -38 -272 -146 -332 -269 -69 -139 -80 -287 -31 -421 l21 -60 290 4 c426 5 429 7 124 64 -140 27 -258 52 -262 56 -17 17 -26 136 -16 211 24 171 124 315 275 398 l59 31 -40 0 c-22 -1 -62 -7 -88 -14z" />
                        <path d="M547 1038 c-8 -13 -72 -131 -142 -263 -70 -132 -139 -260 -152 -285 -30 -54 -47 -72 180 193 117 137 167 187 182 187 44 0 167 -72 229 -134 105 -105 156 -229 156 -381 l1 -80 19 35 c33 61 53 174 47 260 -17 223 -169 410 -386 474 -78 23 -117 21 -134 -6z" />
                        <path d="M624 805 c4 -11 46 -128 92 -259 l84 -240 -31 -32 c-61 -63 -191 -118 -306 -129 -111 -11 -247 26 -352 96 l-34 23 13 -25 c30 -54 135 -146 210 -181 167 -80 329 -77 491 8 65 34 189 149 189 175 0 10 -336 557 -353 574 -7 7 -7 3 -3 -10z" />
                      </g>
                    </svg>
                    <h5 className="logo-title">{translations['logo_title']}</h5>
                  </Link>
                </div>
                <nav id="navbar_main" className={`mobile-offcanvas nav navbar navbar-expand-xl hover-nav horizontal-nav ${navVisible ? 'show' : ''}`}>
                  <div className="container-fluid p-lg-0">
                    <div className="offcanvas-header px-0">
                      <div className="navbar-brand ms-3">
                        <Link to="/" className="navbar-brand m-0">
                          <svg className="icon-30 text-primary" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 108 108">
                            <g transform="translate(0,108) scale(0.1,-0.1)" fill="currentColor" stroke="none">
                              <path d="M397 1045 c-138 -38 -272 -146 -332 -269 -69 -139 -80 -287 -31 -421 l21 -60 290 4 c426 5 429 7 124 64 -140 27 -258 52 -262 56 -17 17 -26 136 -16 211 24 171 124 315 275 398 l59 31 -40 0 c-22 -1 -62 -7 -88 -14z" />
                              <path d="M547 1038 c-8 -13 -72 -131 -142 -263 -70 -132 -139 -260 -152 -285 -30 -54 -47 -72 180 193 117 137 167 187 182 187 44 0 167 -72 229 -134 105 -105 156 -229 156 -381 l1 -80 19 35 c33 61 53 174 47 260 -17 223 -169 410 -386 474 -78 23 -117 21 -134 -6z" />
                              <path d="M624 805 c4 -11 46 -128 92 -259 l84 -240 -31 -32 c-61 -63 -191 -118 -306 -129 -111 -11 -247 26 -352 96 l-34 23 13 -25 c30 -54 135 -146 210 -181 167 -80 329 -77 491 8 65 34 189 149 189 175 0 10 -336 557 -353 574 -7 7 -7 3 -3 -10z" />
                            </g>
                          </svg>
                          <h5 className="logo-title">{translations['logo_title']}</h5>
                        </Link>
                      </div>
                      <button className="btn-close float-end px-3" onClick={toggleNav}></button>
                    </div>
                    <ul className="navbar-nav iq-nav-menu ">
                      <li className="nav-item d-xl-block d-none">
                        <Link to={`/${lang}/`} className="navbar-brand m-0">
                          <svg className="icon-30 text-primary" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 108 108">
                            <g transform="translate(0,108) scale(0.1,-0.1)" fill="currentColor" stroke="none">
                              <path d="M397 1045 c-138 -38 -272 -146 -332 -269 -69 -139 -80 -287 -31 -421 l21 -60 290 4 c426 5 429 7 124 64 -140 27 -258 52 -262 56 -17 17 -26 136 -16 211 24 171 124 315 275 398 l59 31 -40 0 c-22 -1 -62 -7 -88 -14z" />
                              <path d="M547 1038 c-8 -13 -72 -131 -142 -263 -70 -132 -139 -260 -152 -285 -30 -54 -47 -72 180 193 117 137 167 187 182 187 44 0 167 -72 229 -134 105 -105 156 -229 156 -381 l1 -80 19 35 c33 61 53 174 47 260 -17 223 -169 410 -386 474 -78 23 -117 21 -134 -6z" />
                              <path d="M624 805 c4 -11 46 -128 92 -259 l84 -240 -31 -32 c-61 -63 -191 -118 -306 -129 -111 -11 -247 26 -352 96 l-34 23 13 -25 c30 -54 135 -146 210 -181 167 -80 329 -77 491 8 65 34 189 149 189 175 0 10 -336 557 -353 574 -7 7 -7 3 -3 -10z" />
                            </g>
                          </svg>
                          <h5 className="logo-title">{translations['logo_title']}</h5>
                        </Link>
                      </li>
                      <li className="nav-item"><a className="nav-link" href={`/${lang}/`}>{translations['homepage']}</a></li>
                      <li className="nav-item"><a className="nav-link" href={`/${lang}/about-us`}>{translations['about_us']}</a></li>
                      <li className="nav-item">
                        <button style={{ marginTop: '0.1rem' }} className="nav-link menu-arrow justify-content-start" data-bs-toggle="collapse" aria-expanded={servicesMenuOpen ? 'true' : 'false'} onClick={toggleServicesMenu}>
                          <span className="item-name">{translations['our_services']}</span>
                          <FaChevronDown />
                        </button>
                        <ul className={`iq-header-sub-menu list-unstyled collapse ${servicesMenuOpen || isDesktop ? 'show' : ''}`} id="homeData">
                          <li className="nav-item">
                            <a className="nav-link" href={`/${lang}/service-rsvp`}>{translations['service_rsvp']}</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href={`/${lang}/service-rsvp-via-whatsapp`}>{translations['service_whatsapp_rsvp']}</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href={`/${lang}/service-rsvp-via-sms`}>{translations['service_sms_rsvp']}</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href={`/${lang}/service-rsvp-via-link`}>{translations['service_link_rsvp']}</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href={`/${lang}/service-rsvp-via-qr`}>{translations['service_qr_rsvp']}</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href={`/${lang}/service-online-invitation`}>{translations['service_online_invitation']}</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href={`/${lang}/service-memorybook`}>{translations['service_memorybook']}</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href={`/${lang}/service-organization-management`}>{translations['service_organization_management']}</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href={`/${lang}/service-rsvp-via-language-support`}>{translations['service_rsvp_via_language_support']}</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href={`/${lang}/service-247-support`}>{translations['service_247_support']}</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href={`/${lang}/service-seating-plan`}>{translations['service_seating_plan']}</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href={`/${lang}/service-usher-screen`}>{translations['service_usher_screen']}</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href={`/${lang}/service-qualified-sms`}>{translations['service_qualified_sms']}</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href={`/${lang}/service-custom-forms`}>{translations['service_custom_forms']}</a>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href={`/${lang}/contact`}>{translations['contact']}</a>
                        </li>
                      {/* <li className="nav-item"><a className="nav-link" href={`/${lang}/faq`}>{translations['faq']}</a></li> */}
                      <li className="nav-item"><a className="nav-link" href={`/${lang}/references`}>{translations['references']}</a></li>
                    </ul>
                  </div>
                </nav>
                <ul className="d-flex d-xl-none align-items-center list-unstyled m-0">
                  <LanguageSelector />
                  <LoginDropdown />
                </ul>
                <ul className="navbar-nav align-items-center navbar-list d-none d-xl-flex">
                  <LanguageSelector />
                  <LoginDropdown />
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <Outlet />
      </main>
      <footer>
        <div className="bg-secondary inner-box ">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <a href="/" className="navbar-brand d-flex align-items-center">
                  <svg className="icon-30 text-primary" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 108 108">
                    <g transform="translate(0,108) scale(0.1,-0.1)" fill="currentColor" stroke="none">
                      <path d="M397 1045 c-138 -38 -272 -146 -332 -269 -69 -139 -80 -287 -31 -421 l21 -60 290 4 c426 5 429 7 124 64 -140 27 -258 52 -262 56 -17 17 -26 136 -16 211 24 171 124 315 275 398 l59 31 -40 0 c-22 -1 -62 -7 -88 -14z" />
                      <path d="M547 1038 c-8 -13 -72 -131 -142 -263 -70 -132 -139 -260 -152 -285 -30 -54 -47 -72 180 193 117 137 167 187 182 187 44 0 167 -72 229 -134 105 -105 156 -229 156 -381 l1 -80 19 35 c33 61 53 174 47 260 -17 223 -169 410 -386 474 -78 23 -117 21 -134 -6z" />
                      <path d="M624 805 c4 -11 46 -128 92 -259 l84 -240 -31 -32 c-61 -63 -191 -118 -306 -129 -111 -11 -247 26 -352 96 l-34 23 13 -25 c30 -54 135 -146 210 -181 167 -80 329 -77 491 8 65 34 189 149 189 175 0 10 -336 557 -353 574 -7 7 -7 3 -3 -10z" />
                    </g>
                  </svg>
                  <h4 className="logo-title ms-3 text-white">{translations['logo_title']}</h4>
                </a>
                <p className="mb-4 mt-4">{translations['company_description']}</p>
                <div className="d-flex align-items-center pt-2 mb-3">
                  <FaMapMarked size={40} color="white" />
                  <p className="ms-4 mb-0 text-white">{translations['address']}</p>
                </div>
                <div className="d-flex align-items-center pt-2 mb-3">
                  <FaPhoneAlt size={30} color="white" />
                  <p className="ms-4 mb-0 text-white">{translations['phone']}</p>
                </div>
                <div className="d-flex align-items-center pt-2 mb-3">
                  <FaMailBulk size={30} color="white" />
                  <p className="ms-4 mb-0 text-white">{translations['email']}</p>
                </div>
              </div>

              <div className="col-md-4 mt-md-0 mt-4">
                <h5 className="mb-4 text-white">{translations['site_map']}</h5>
                <div className="row">
                  <ul className="col-lg-6 col-12 m-0 p-0 list-unstyled text-white">
                  <li className="mb-3">
                    <FaCircle size={8} className="text-primary" />
                    {' '}
                    <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/about-us`}>{translations['about_us']}</a>
                  </li>
                  <li className="mb-3">
                    <FaCircle size={8} className="text-primary" />
                    {' '}
                    <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/contact`}>{translations['contact']}</a>
                  </li>
                  {/* <li className="mb-3">
                    <FaCircle size={8} className="text-primary"/>
                    {' '}
                    <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/faq`}>{translations['faq']}</a>
                  </li> */}
                  <li className="mb-3">
                    <FaCircle size={8} className="text-primary" />
                    {' '}
                    <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/references`}>{translations['references']}</a>
                  </li>
                    <li className="mb-3">
                      <FaCircle size={8} className="text-primary" />
                      {' '}
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/service-rsvp`}>{translations['service_rsvp']}</a>
                    </li>
                    <li className="mb-3">
                      <FaCircle size={8} className="text-primary" />
                      {' '}
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/service-rsvp-via-whatsapp`}>{translations['service_whatsapp_rsvp']}</a>
                    </li>
                    <li className="mb-3">
                      <FaCircle size={8} className="text-primary" />
                      {' '}
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/service-rsvp-via-sms`}>{translations['service_sms_rsvp']}</a>
                    </li>
                    <li className="mb-3">
                      <FaCircle size={8} className="text-primary" />
                      {' '}
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/service-rsvp-via-link`}>{translations['service_link_rsvp']}</a>
                    </li>
                    <li className="mb-3">
                      <FaCircle size={8} className="text-primary" />
                      {' '}
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/service-rsvp-via-qr`}>{translations['service_qr_rsvp']}</a>
                    </li>
                    

                  </ul>
                  <ul className="col-lg-6 col-12 m-0 p-0 list-unstyled text-white">
                  <li className="mb-3">
                      <FaCircle size={8} className="text-primary" />
                      {' '}
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/service-online-invitation`}>{translations['service_online_invitation']}</a>
                    </li>
                    <li className="mb-3">
                      <FaCircle size={8} className="text-primary" />
                      {' '}
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/service-memorybook`}>{translations['service_memorybook']}</a>
                    </li>
                    <li className="mb-3">
                      <FaCircle size={8} className="text-primary" />
                      {' '}
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/service-organization-management`}>{translations['service_organization_management']}</a>
                    </li>
                    <li className="mb-3">
                      <FaCircle size={8} className="text-primary" />
                      {' '}
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/service-rsvp-via-language-support`}>{translations['service_rsvp_via_language_support']}</a>
                    </li>
                    <li className="mb-3">
                      <FaCircle size={8} className="text-primary" />
                      {' '}
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/service-247-support`}>{translations['service_247_support']}</a>
                    </li>
                    <li className="mb-3">
                      <FaCircle size={8} className="text-primary" />
                      {' '}
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/service-seating-plan`}>{translations['service_seating_plan']}</a>
                    </li>
                    <li className="mb-3">
                      <FaCircle size={8} className="text-primary" />
                      {' '}
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/service-usher-screen`}>{translations['service_usher_screen']}</a>
                    </li>
                    <li className="mb-3">
                      <FaCircle size={8} className="text-primary" />
                      {' '}
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/service-qualified-sms`}>{translations['service_qualified_sms']}</a>
                    </li>
                    <li className="mb-3">
                      <FaCircle size={8} className="text-primary" />
                      {' '}
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href={`/${lang}/service-custom-forms`}>{translations['service_custom_forms']}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 mt-md-0 mt-4">
                <h5 className="text-white">{translations['save_us_to_your_contacts']}</h5>
                <img src={eksiksiz_qrcode} alt="Eksiksiz Organizasyonlar QR Kodu" className="img-fluid pt-2" />
                <h5 className="text-white pt-5">{translations['stay_tuned']}</h5>
                <ul className="list-unstyled p-0 m-0 d-flex mt-4 social-icon">
                  <li>
                    <a href="https://www.facebook.com/AlbinaSoft/" target="blank">
                      <FaFacebook size={40} color="white" />
                    </a>
                  </li>
                  <li className="ps-3">
                    <a href="https://www.linkedin.com/company/albinasoft-a-%C5%9F-/" target="blank">
                      <FaLinkedin size={40} color="white" />
                    </a>
                  </li>
                  <li className="ps-3">
                    <a href="https://www.instagram.com/albinasofttr/" target="blank">
                      <FaInstagram size={40} color="white" />
                    </a>
                  </li>
                  <li className="ps-3">
                    <a href="https://www.youtube.com/channel/UC8kkCbTFSg_s6rmYsV-yIyQ/" target="blank">
                      <FaYoutube size={40} color="white" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom bg-secondary ">
          <div className="container py-4 footer-border">
            <div className="row">
              <div className="col-md-12 text-center text-white">
                <p className="mb-0">
                  © {currentYear} {translations['footer_text']}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <BackToTop />
    </div>
  );
};

export default Layout;
