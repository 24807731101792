import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { LanguageContext } from '../../../../lib/i18n/LanguageProvider';
import { useParams } from 'react-router-dom';

import topHeaderImage from '../../../../assets/images/banners/top_header.webp';

import usherscreen_1 from '../../../../assets/images/banners/usherscreen_1.png';

import { FaCheck } from 'react-icons/fa';

const UsherScreen: React.FC = () => {
    const { lang } = useParams();
    const context = useContext(LanguageContext);

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['service_usher_screen'] ? `${translations['service_usher_screen']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">

                <div
                    className="sub-header"
                    style={{
                        backgroundImage: `url(${topHeaderImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        // height: '400px'
                    }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className="text-white mb-4">{translations['service_usher_screen']}</h3>
                                <p>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odio sapiente culpa sunt exercitationem neque explicabo, voluptates aspernatur necessitatibus, quasi ipsum hic iste tempore rem possimus eligendi delectus illo, dolorum debitis!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding ">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 mb-4 mb-md-0">
                                <h2 className="mb-5">
                                    Hostes Ekranı
                                    {' '}
                                    <span className="text-primary">
                                        Nedir? Nasıl Kullanılır?
                                    </span>
                                </h2>
                                <p className="text-start text-black">
                                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Commodi, omnis? Architecto reprehenderit modi ad hic sequi iusto dolorem consectetur? Laboriosam recusandae sint ad voluptates minima neque velit molestiae aspernatur adipisci animi nobis, soluta labore autem facere necessitatibus provident architecto delectus saepe tempore natus deleniti. Delectus.
                                </p>
                                <div className="row row-cols-1">
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>
                                                Lorem ipsum dolor sit amet.
                                            </h6>
                                        </div>
                                        <p className="text-black">
                                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias libero eveniet maiores. Possimus, voluptates molestias!
                                        </p>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>
                                                Lorem ipsum dolor sit amet consectetur adipisicing.
                                            </h6>
                                        </div>
                                        <p className="text-black">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam harum, voluptatum facilis saepe magni suscipit quod.
                                        </p>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>
                                                Lorem ipsum dolor sit amet consectetur.
                                            </h6>
                                        </div>
                                        <p className="text-black">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eos repudiandae aut in consequuntur reprehenderit, quaerat eum consectetur iste!
                                        </p>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>
                                                Lorem ipsum dolor sit.
                                            </h6>
                                        </div>
                                        <p className="text-black">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt quisquam voluptas impedit fuga repellendus laudantium illum consectetur voluptatem. Cum, voluptate!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={usherscreen_1} alt="Banner" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default UsherScreen;
