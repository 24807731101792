import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { LanguageContext } from '../../../../lib/i18n/LanguageProvider';
import { useParams } from 'react-router-dom';

import topHeaderImage from '../../../../assets/images/banners/top_header.webp';

import rsvpvialanguagesupport_1 from '../../../../assets/images/banners/rsvpvialanguagesupport_1.png';

import { FaCheck } from 'react-icons/fa';

const RSVPviaLanguageSupport: React.FC = () => {
    const { lang } = useParams();
    const context = useContext(LanguageContext);

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['rsvpvialanguagesupport_service'] ? `${translations['rsvpvialanguagesupport_service']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">

                <div
                    className="sub-header"
                    style={{
                        backgroundImage: `url(${topHeaderImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className="text-white mb-4">{translations['rsvpvialanguagesupport_service']}</h3>
                                <p>{translations['rsvpvialanguagesupport_description']}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 mb-4 mb-md-0">
                                <h2 className="mb-5">
                                    {translations['rsvpvialanguagesupport_title_pre_primary']}
                                    {' '}
                                    <span className="text-primary">
                                        {translations['rsvpvialanguagesupport_title_primary']}
                                    </span>
                                    {' '}
                                    {translations['rsvpvialanguagesupport_title_post_primary']}
                                </h2>
                                <div className="row row-cols-1">
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['rsvpvialanguagesupport_support_for_foreign_guests']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['rsvpvialanguagesupport_support_for_foreign_guests_desc']}
                                        </p>
                                    </div>

                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['rsvpvialanguagesupport_personalized_whatsapp_invites']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['rsvpvialanguagesupport_personalized_whatsapp_invites_desc']}
                                        </p>
                                    </div>

                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['rsvpvialanguagesupport_multilingual_options']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['rsvpvialanguagesupport_multilingual_options_desc']}
                                        </p>
                                    </div>

                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['rsvpvialanguagesupport_global_reach']}</h6>
                                        </div>
                                        <p className="text-black">
                                            {translations['rsvpvialanguagesupport_global_reach_desc']}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={rsvpvialanguagesupport_1} alt="Banner" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default RSVPviaLanguageSupport;
