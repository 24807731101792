import React, { useState } from 'react';

interface LoadingButtonProps {
    label: React.ReactNode; // label prop'u hem metin hem de React bileşeni olabilir. string veya font awesome olabilir
    onClick: (event: React.FormEvent) => void; // Adjusted to accept FormEvent
    style?: React.CSSProperties; // style prop'u opsiyonel ve CSSProperties türünde
    className?: string;
    tooltip?: string; // Opsiyonel tooltip prop'u
}

const LoadingButton: React.FC<LoadingButtonProps> = ({ label, onClick, style, className, tooltip }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async (event: React.FormEvent) => {
        setIsLoading(true);
        try {
            await onClick(event);
        } finally {
            setIsLoading(false);
        }
    };

    const buttonClassName = className || 'btn btn-primary';

    return (
        <button
            className={buttonClassName}
            onClick={handleClick}
            disabled={isLoading}
            style={style}
            title={tooltip} // Mouse üzerine gelindiğinde yazı göstermek için title kullanılıyor
        >
            {isLoading ? (
                <span className="spinner spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            ) : (
                label
            )}
        </button>
    );
};

export default LoadingButton;
