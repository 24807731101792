import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { LanguageContext } from '../../../../lib/i18n/LanguageProvider';
import { useParams } from 'react-router-dom';

import topHeaderImage from '../../../../assets/images/banners/top_header.webp';

import { FaChartArea, FaGlobe, FaLink, FaShare } from 'react-icons/fa';

const RSVPviaLink: React.FC = () => {
    const { lang } = useParams();
    const context = useContext(LanguageContext);

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['linkrsvp_service'] ? `${translations['linkrsvp_service']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">

                <div
                    className="sub-header"
                    style={{
                        backgroundImage: `url(${topHeaderImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className="text-white mb-4">{translations['linkrsvp_service']}</h3>
                                <p>{translations['linkrsvp_description']}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding bg-body">
                    <div className="container">
                        <div className="row align-items-center mb-4 mb-lg-5">
                            <div className="col-lg-8">
                                <div className="mb-2 text-uppercase text-primary sub-title">
                                    {translations['customer_service_at_your_fingertips_pretitle']}
                                </div>
                                <h2 className="mb-5">
                                    {translations['linkrsvp_title_pre_primary']}
                                    {' '}
                                    <span className="text-primary">
                                        {translations['linkrsvp_title_primary']}
                                    </span>
                                    {' '}
                                    {translations['linkrsvp_title_post_primary']}
                                </h2>
                            </div>
                        </div>
                        <div className="row align-items-center pt-4 pt-lg-5">
                            <div className="col-12">
                                <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2">
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaLink size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">{translations['linkrsvp_get_link']}</h6>
                                                <p className="mb-4">
                                                    {translations['linkrsvp_get_link_desc']}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaGlobe size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">{translations['linkrsvp_use_language_support']}</h6>
                                                <p className="mb-4">
                                                    {translations['linkrsvp_use_language_support_desc']}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaShare size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">{translations['linkrsvp_share_on_social']}</h6>
                                                <p className="mb-4">
                                                    {translations['linkrsvp_share_on_social_desc']}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card feature-card card-block card-stretch card-height">
                                            <div className="card-body">
                                                <FaChartArea size={50} className="mb-4 text-primary" />
                                                <h6 className="mb-4">{translations['linkrsvp_analyze_in_real_time']}</h6>
                                                <p className="mb-4">
                                                    {translations['linkrsvp_analyze_in_real_time_desc']}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RSVPviaLink;
