import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { LanguageContext } from '../../../lib/i18n/LanguageProvider';

import topHeaderImage from '../../../assets/images/banners/top_header.webp';

import bannercontact from '../../../assets/images/banners/faq_1.webp';

const Faq: React.FC = () => {
    const context = useContext(LanguageContext);

    const [openAccordion, setOpenAccordion] = useState<number | null>(null);

    const handleAccordionClick = (index: number) => {
        setOpenAccordion(openAccordion === index ? null : index);
    };

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['faq'] ? `${translations['faq']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">

                <div
                    className="sub-header"
                    style={{
                        backgroundImage: `url(${topHeaderImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        // height: '400px'
                    }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className="text-white mb-4">{translations['faq_long']}</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-xl-6">
                                    <div className="mb-2 text-uppercase text-primary sub-title">S.S.S</div>
                                    <h2 className="text-secondary heading-title">
                                        En Sık Karşılaştığımız <span className="text-primary">Sorular</span>
                                    </h2>
                                    <p className="mb-0 mt-2 text-black">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore
                                        et dolore magna aliqua.
                                    </p>
                                </div>
                                <div className="col-lg-6 col-xl-6 mt-4 mt-lg-0">
                                    <div className="accordion custom-accordion faq" id="accordionExample">
                                        {/* First Accordion */}
                                        <div className="accordion-item mb-4 pb-2 border-bottom rounded-0">
                                            <div className="accordion-header" id="headingOne">
                                                <button
                                                    className="accordion-button collapsed px-0 h5 mb-0"
                                                    type="button"
                                                    onClick={() => handleAccordionClick(1)}
                                                    aria-expanded={openAccordion === 1}
                                                    aria-controls="collapseOne"
                                                >
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                                                </button>
                                            </div>
                                            <div
                                                id="collapseOne"
                                                className={`accordion-collapse collapse ${openAccordion === 1 ? 'show' : ''}`}
                                                aria-labelledby="headingOne"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body px-0">
                                                    <p className="mb-0">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Second Accordion */}
                                        <div className="accordion-item mb-4 pb-2 border-bottom rounded-0">
                                            <div className="accordion-header" id="headingTwo">
                                                <button
                                                    className="accordion-button collapsed px-0 h5 mb-0"
                                                    type="button"
                                                    onClick={() => handleAccordionClick(2)}
                                                    aria-expanded={openAccordion === 2}
                                                    aria-controls="collapseTwo"
                                                >
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                                                </button>
                                            </div>
                                            <div
                                                id="collapseTwo"
                                                className={`accordion-collapse collapse ${openAccordion === 2 ? 'show' : ''}`}
                                                aria-labelledby="headingTwo"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body px-0">
                                                    <p className="mb-0">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Third Accordion */}
                                        <div className="accordion-item mb-4 pb-2 border-bottom rounded-0">
                                            <div className="accordion-header" id="headingThree">
                                                <button
                                                    className="accordion-button collapsed h5 px-0 mb-0"
                                                    type="button"
                                                    onClick={() => handleAccordionClick(3)}
                                                    aria-expanded={openAccordion === 3}
                                                    aria-controls="collapseThree"
                                                >
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                                                </button>
                                            </div>
                                            <div
                                                id="collapseThree"
                                                className={`accordion-collapse collapse ${openAccordion === 3 ? 'show' : ''}`}
                                                aria-labelledby="headingThree"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body px-0">
                                                    <p className="mb-0">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Fourth Accordion */}
                                        <div className="accordion-item mb-4 pb-2 border-bottom rounded-0">
                                            <div className="accordion-header" id="headingFour">
                                                <button
                                                    className="accordion-button collapsed h5 px-0 mb-0"
                                                    type="button"
                                                    onClick={() => handleAccordionClick(4)}
                                                    aria-expanded={openAccordion === 4}
                                                    aria-controls="collapseFour"
                                                >
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                                                </button>
                                            </div>
                                            <div
                                                id="collapseFour"
                                                className={`accordion-collapse collapse ${openAccordion === 4 ? 'show' : ''}`}
                                                aria-labelledby="headingFour"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body px-0">
                                                    <p className="mb-0">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Fifth Accordion */}
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="headingFive">
                                                <button
                                                    className="accordion-button collapsed h5 px-0 mb-0"
                                                    type="button"
                                                    onClick={() => handleAccordionClick(5)}
                                                    aria-expanded={openAccordion === 5}
                                                    aria-controls="collapseFive"
                                                >
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                                                </button>
                                            </div>
                                            <div
                                                id="collapseFive"
                                                className={`accordion-collapse collapse ${openAccordion === 5 ? 'show' : ''}`}
                                                aria-labelledby="headingFive"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body px-0">
                                                    <p className="mb-0">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-padding pt-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <img
                                        src={bannercontact}
                                        alt=""
                                        className="img-fluid mb-4 pb-3"
                                    />
                                </div>
                                <div className="col-lg-7 col-md-12 text-lg-start text-center pt-lg-2 pt-0">
                                    <div className="text-primary text-uppercase sub-title mb-2">DAHA FAZLASI İÇİN</div>
                                    <h2 className="mb-0">
                                        Bizimle <br />
                                        <span className="text-primary">İletişime Geçin</span> 
                                    </h2>
                                </div>
                                <div className="col-lg-5 contact-detail mt-4 mt-lg-0 pt-lg-2 pt-0">
                                    <h5 className="mb-4">Üyelikler, ücretlendirmeler, sistemin kullanımı vb.</h5>
                                    <h6 className="mb-3">info@techrsvp.com</h6>
                                    <h6 className="mb-0">+90(850) 840 10 24</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faq;
