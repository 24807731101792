import React, { useContext } from 'react';
import { LanguageContext } from '../../../lib/i18n/LanguageProvider';
import { Helmet } from 'react-helmet-async';

const PageNotFound: React.FC = () => {
  const context = useContext(LanguageContext);

  if (!context) {
    return null;
  }

  const { translations } = context;

  return (
    <div>
      <Helmet>
        <title>{translations['404Title'] ? `${translations['404Title']}` : '404'}</title>
      </Helmet>
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <h1>{translations['404Title']}</h1>
        <p>{translations['404Text']}</p>
      </div>
    </div>

  );
};

export default PageNotFound;
