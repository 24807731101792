import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { LanguageContext } from '../../../lib/i18n/LanguageProvider';
import { Helmet } from 'react-helmet-async';

import homepage_1 from '../../../assets/images/banners/homepage_1.png';
import homepage_2 from '../../../assets/images/banners/homepage_2.png';
import homepage_3 from '../../../assets/images/banners/homepage_3.png';
import homepage_4 from '../../../assets/images/banners/homepage_4.png';
import homepage_5 from '../../../assets/images/banners/homepage_5.png';

import { FaBookOpen, FaChair, FaClock, FaDotCircle, FaEdit, FaEnvelope, FaEnvelopeOpenText, FaGlobe, FaHeadphones, FaLink, FaQrcode, FaQuestion, FaSms, FaTable, FaUsers, FaUsersCog, FaWhatsapp } from 'react-icons/fa';

const Homepage: React.FC = () => {
    const { lang } = useParams();
    const context = useContext(LanguageContext);

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['homepage_title'] ? `${translations['homepage_title']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">

                <div className="inner-box bg-secondary">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="mb-2 text-uppercase sub-title">
                                    {translations['homepage_leader_pretitle']}
                                </div>
                                <h1 className="text-white text-capitalize">
                                    {translations['homepage_leader_title_pre_primary']}
                                    {' '}
                                    <span className="text-primary">
                                        {translations['homepage_leader_title_primary']}
                                    </span>
                                    {' '}
                                    {translations['homepage_leader_title_post_primary']}
                                </h1>
                                <p className="mb-5">
                                    {translations['homepage_leader_description']}
                                </p>
                                <div className="d-flex align-items-center mb-lg-0 mb-4 pt-2">
                                    <a href={`/${lang}/individual-register`} className="btn btn-primary text-capitalize">
                                        {translations['homepage_start_now']}
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <img src={homepage_1} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding bg-body">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <img src={homepage_2} alt="Banner" className="img-fluid" />
                            </div>
                            <div className="col-md-6 mb-4 mb-md-0">
                                <div className="mb-2 text-uppercase text-primary sub-title">
                                    {translations['homepage_subtitle_sub']}
                                </div>
                                <h2 className="mb-5">
                                    {translations['homepage_subtitle_pre_primary']}
                                    {' '}
                                    <span className="text-primary">
                                        {translations['homepage_subtitle_primary']}
                                    </span>
                                    {' '}
                                    {translations['homepage_subtitle_post_primary']}
                                </h2>
                                <p className="mb-5 text-black">
                                    {translations['homepage_service_description']}
                                </p>
                                <div className="row row-cols-1">
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaDotCircle size={30} className="text-primary m-1 pb-2" />
                                            <h6>
                                                {translations['homepage_feature_customer_support']}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaDotCircle size={30} className="text-primary m-1 pb-2" />
                                            <h6>
                                                {translations['homepage_feature_special_rsvp']}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaDotCircle size={30} className="text-primary m-1 pb-2" />
                                            <h6>
                                                {translations['homepage_feature_custom_portal']}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <a className="btn btn-primary m-1" href={`/${lang}/individual-register`}>
                                            {translations['homepage_register_individual']}
                                        </a>
                                        <a className="btn btn-primary m-1" href={`/${lang}/corporate-register`}>
                                            {translations['homepage_register_corporate']}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 mb-4 mb-md-0">
                                <div className="mb-2 text-uppercase text-primary sub-title">
                                    {translations['homepage_different']}
                                </div>
                                <h2 className="mb-4 mb-lg-5">
                                    {translations['homepage_meta_partner_pre_primary']}
                                    {' '}
                                    <span className="text-primary"> {translations['homepage_meta_partner_primary']}</span>
                                    {' '}
                                    {translations['homepage_meta_partner_post_primary']}
                                </h2>
                                <p className="text-start text-black">
                                    {translations['homepage_meta_description1']}
                                </p>
                                <p className="text-start text-black">
                                    {translations['homepage_meta_description2']}
                                </p>
                            </div>
                            <div className="col-md-6">
                                <img src={homepage_5} alt="Banner" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding bg-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <div className="mb-2 text-uppercase text-primary sub-title">
                                    {translations['homepage_not_just_an_rsvp_firm']}
                                </div>
                                <h2 className="mb-4 mb-lg-5">
                                    {translations['homepage_we_are_an_rnd_firm_pre_primary']}
                                    <br />
                                    <span className="text-primary">{translations['homepage_we_are_an_rnd_firm_primary']}</span>
                                    <br />
                                    {translations['homepage_we_are_an_rnd_firm_post_primary']}
                                </h2>
                            </div>
                        </div>
                        <div className="row row-cols-md-4 row-cols-sm-2">
                            <a href={`/${lang}/service-rsvp`} className="col">
                                <div className="card feature-card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <FaHeadphones size={50} className="text-primary m-1 pb-2" />
                                        <h6 className="mb-4">{translations['homepage_rsvp_service']}</h6>
                                        <p className="mb-4">{translations['homepage_rsvp_service_description']}</p>
                                    </div>
                                </div>
                            </a>
                            <a href={`/${lang}/service-rsvp`} className="col">
                                <div className="card feature-card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <FaUsersCog size={50} className="text-primary m-1 pb-2" />
                                        <h6 className="mb-4">{translations['homepage_organization_management']}</h6>
                                        <p className="mb-4">{translations['homepage_organization_management_description']}</p>
                                    </div>
                                </div>
                            </a>
                            <a href={`/${lang}/service-online-invitation`} className="col">
                                <div className="card feature-card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <FaEnvelopeOpenText size={50} className="text-primary m-1 pb-2" />
                                        <h6 className="mb-4">{translations['homepage_online_invitation']}</h6>
                                        <p className="mb-4">{translations['homepage_online_invitation_description']}</p>
                                    </div>
                                </div>
                            </a>
                            <a href={`/${lang}/service-rsvp-via-link`} className="col">
                                <div className="card feature-card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <FaGlobe size={50} className="text-primary m-1 pb-2" />
                                        <h6 className="mb-4">{translations['homepage_language_support']}</h6>
                                        <p className="mb-4">{translations['homepage_language_support_description']}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row align-items-center mb-4 mb-lg-5">
                            <div className="col-lg-6">
                                <div className="mb-2 text-uppercase text-primary sub-title">
                                    {translations['homepage_special_rsvp_subtitle']}
                                </div>
                                <h2 className="mb-0">
                                    {translations['homepage_special_rsvp_title_pre_primary']}
                                    {' '}
                                    <span className="text-primary">
                                        {translations['homepage_special_rsvp_title_primary']}
                                    </span>
                                    {' '}
                                    {translations['homepage_special_rsvp_title_post_primary']}
                                </h2>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb-2">
                                    {translations['homepage_special_rsvp_paragraph']}
                                </p>
                                <a
                                    href={`/${lang}/service-rsvp`}
                                    rel="noopener noreferrer"
                                    className="btn btn-primary mt-4 mt-lg-5 mb-5 mb-lg-0"
                                >
                                    {translations['homepage_special_rsvp_button']}
                                </a>
                            </div>
                        </div>
                        <div className="row align-items-center pt-4 pt-lg-5">
                            <div className="col-md-8">
                                <img
                                    src={homepage_3}
                                    alt="Banner Left"
                                    className="img-fluid mb-4 mb-lg-0"
                                />
                            </div>
                            <div className="col-md-4">
                                <img
                                    src={homepage_4}
                                    alt="Banner Right"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding contact-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <div className="mb-2 text-uppercase text-primary sub-title">
                                    {translations['homepage_services_subtitle']}
                                </div>
                                <h2 className="mb-4 mb-lg-5 text-white">
                                    {translations['homepage_services_title_pre_primary']}
                                    {' '}
                                    <span className="text-primary">
                                        {translations['homepage_services_title_primary']}
                                    </span>
                                    {' '}
                                    {translations['homepage_services_title_post_primary']}
                                </h2>
                                <p className="mb-5">
                                    {translations['homepage_services_paragraph']}
                                </p>
                            </div>
                        </div>
                        <div className="row row-cols-md-4 row-cols-sm-2">
                            <a href={`/${lang}/service-rsvp`} className="col">
                                <div className="card feature-card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <FaUsers size={50} className="mb-4 text-primary" />
                                        <h6 className="mb-4">
                                            {translations['homepage_service_rsvp']}
                                        </h6>
                                        <p className="mb-4">
                                            {translations['homepage_service_rsvp_desc']}
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a href={`/${lang}/contact`} className="col">
                                <div className="card feature-card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <FaClock size={50} className="mb-4 text-primary" />
                                        <h6 className="mb-4">
                                            {translations['homepage_service_support']}
                                        </h6>
                                        <p className="mb-4">
                                            {translations['homepage_service_support_desc']}
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a href={`/${lang}/service-online-invitation`} className="col">
                                <div className="card feature-card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <FaEnvelope size={50} className="mb-4 text-primary" />
                                        <h6 className="mb-4">
                                            {translations['homepage_service_online_invitation']}
                                        </h6>
                                        <p className="mb-4">
                                            {translations['homepage_service_online_invitation_desc']}
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a href={`/${lang}/service-memorybook`} className="col">
                                <div className="card feature-card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <FaBookOpen size={50} className="mb-4 text-primary" />
                                        <h6 className="mb-4">
                                            {translations['homepage_service_memorybook']}
                                        </h6>
                                        <p className="mb-4">
                                            {translations['homepage_service_memorybook_desc']}
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a href={`/${lang}/service-seating-plan`} className="col">
                                <div className="card feature-card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <FaChair size={50} className="mb-4 text-primary" />
                                        <h6 className="mb-4">
                                            {translations['homepage_service_seating_plan']}
                                        </h6>
                                        <p className="mb-4">
                                            {translations['homepage_service_seating_plan_desc']}
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a href={`/${lang}/service-usher-screen`} className="col">
                                <div className="card feature-card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <FaTable size={50} className="mb-4 text-primary" />
                                        <h6 className="mb-4">
                                            {translations['homepage_service_usher_screen']}
                                        </h6>
                                        <p className="mb-4">
                                            {translations['homepage_service_usher_screen_desc']}
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a href={`/${lang}/service-rsvp-via-whatsapp`} className="col">
                                <div className="card feature-card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <FaWhatsapp size={50} className="mb-4 text-primary" />
                                        <h6 className="mb-4">
                                            {translations['homepage_service_whatsapp_rsvp']}
                                        </h6>
                                        <p className="mb-4">
                                            {translations['homepage_service_whatsapp_rsvp_desc']}
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a href={`/${lang}/service-rsvp-via-sms`} className="col">
                                <div className="card feature-card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <FaSms size={50} className="mb-4 text-primary" />
                                        <h6 className="mb-4">
                                            {translations['homepage_service_sms_rsvp']}
                                        </h6>
                                        <p className="mb-4">
                                            {translations['homepage_service_sms_rsvp_desc']}
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a href={`/${lang}/service-rsvp-via-link`} className="col">
                                <div className="card feature-card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <FaLink size={50} className="mb-4 text-primary" />
                                        <h6 className="mb-4">
                                            {translations['homepage_service_link_rsvp']}
                                        </h6>
                                        <p className="mb-4">
                                            {translations['homepage_service_link_rsvp_desc']}
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a href={`/${lang}/service-rsvp-via-qr`} className="col">
                                <div className="card feature-card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <FaQrcode size={50} className="mb-4 text-primary" />
                                        <h6 className="mb-4">
                                            {translations['homepage_service_qr_rsvp']}
                                        </h6>
                                        <p className="mb-4">
                                            {translations['homepage_service_qr_rsvp_desc']}
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a href={`/${lang}/service-qualified-sms`} className="col">
                                <div className="card feature-card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <FaEdit size={50} className="mb-4 text-primary" />
                                        <h6 className="mb-4">
                                            {translations['homepage_service_qualified_sms']}
                                        </h6>
                                        <p className="mb-4">
                                            {translations['homepage_service_qualified_sms_desc']}
                                        </p>
                                    </div>
                                </div>
                            </a>
                            <a href={`/${lang}/service-custom-forms`} className="col">
                                <div className="card feature-card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <FaQuestion size={50} className="mb-4 text-primary" />
                                        <h6 className="mb-4">
                                            {translations['homepage_service_custom_forms']}
                                        </h6>
                                        <p className="mb-4">
                                            {translations['homepage_service_custom_forms_desc']}
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    );
};

export default Homepage;
