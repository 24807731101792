import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { LanguageProvider } from '../lib/i18n/LanguageProvider';
import { getStorageLang } from '../stores/Language';
import Layout from './routes/layout/Layout';
import LanguageWrapper from './routes/layout/LanguageWrapper';

import Homepage from './routes/pages/Homepage';
import AboutUs from './routes/pages/AboutUs';
import Contact from './routes/pages/Contact';
import Faq from './routes/pages/Faq';
import References from './routes/pages/References';

import RSVPService from './routes/pages/our-services/RSVPService';
import OnlineInvitation from './routes/pages/our-services/OnlineInvitation';
import MemoryBook from './routes/pages/our-services/MemoryBook';
import SeatingPlan from './routes/pages/our-services/SeatingPlan';
import UsherScreen from './routes/pages/our-services/UsherScreen';
import RSVPviaWhatsapp from './routes/pages/our-services/RSVPviaWhatsapp';
import RSVPviaSMS from './routes/pages/our-services/RSVPviaSMS';
import RSVPviaLink from './routes/pages/our-services/RSVPviaLink';
import RSVPviaQR from './routes/pages/our-services/RSVPviaQR';
import QualifiedSMS from './routes/pages/our-services/QualifiedSMS';
import CustomForms from './routes/pages/our-services/CustomForms';
import IndividualLogin from './routes/pages/user-screens/IndividualLogin';
import IndividualRegister from './routes/pages/user-screens/IndividualRegister';
import CorporateLogin from './routes/pages/user-screens/CorporateLogin';
import CorporateRegister from './routes/pages/user-screens/CorporateRegister';
import IndividualPricing from './routes/pages/user-screens/IndividualPricing';
import ResetPassword from './routes/pages/user-screens/ResetPassword';
import OrganizationManagement from './routes/pages/our-services/OrganizationManagement';
import RSVPviaLanguageSupport from './routes/pages/our-services/RSVPviaLanguageSupport';
import TwentyFourSevenSupport from './routes/pages/our-services/TwentyFourSevenSupport';
import LanguageGuard from './routes/layout/LanguageGuard';
import PageNotFound from './routes/pages/PageNotFound';


const App: React.FC = () => {
  const storedLang = getStorageLang() || 'en-US'; // localStorage'dan dil al, yoksa varsayılan 'en-US'


  return (

    <Router>
      <LanguageProvider>
        <HelmetProvider>
          <Routes>
            {/* Boş path durumunda kullanıcının diline yönlendirme yap */}
            <Route path="/" element={<Navigate to={`/${storedLang}`} replace />} />

            {/* Dil parametresi olan rotalar */}
            <Route path="/:lang/*" element={
              <LanguageGuard>
                <LanguageWrapper>
                  <Layout />
                </LanguageWrapper>
              </LanguageGuard>
            }>
              <Route path="*" element={<PageNotFound />} />

              <Route path="" element={<Homepage />} />
              <Route path="about-us" element={<AboutUs />} />
              <Route path="contact" element={<Contact />} />
              <Route path="faq" element={<Faq />} />
              <Route path="references" element={<References />} />

              <Route path="service-rsvp" element={<RSVPService />} />
              <Route path="service-online-invitation" element={<OnlineInvitation />} />
              <Route path="service-memorybook" element={<MemoryBook />} />
              <Route path="service-seating-plan" element={<SeatingPlan />} />
              <Route path="service-usher-screen" element={<UsherScreen />} />
              <Route path="service-rsvp-via-whatsapp" element={<RSVPviaWhatsapp />} />
              <Route path="service-rsvp-via-sms" element={<RSVPviaSMS />} />
              <Route path="service-rsvp-via-link" element={<RSVPviaLink />} />
              <Route path="service-rsvp-via-qr" element={<RSVPviaQR />} />
              <Route path="service-qualified-sms" element={<QualifiedSMS />} />
              <Route path="service-custom-forms" element={<CustomForms />} />
              <Route path="service-organization-management" element={<OrganizationManagement />} />
              <Route path="service-rsvp-via-language-support" element={<RSVPviaLanguageSupport />} />
              <Route path="service-247-support" element={<TwentyFourSevenSupport />} />

              <Route path="individual-login" element={<IndividualLogin />} />
              <Route path="individual-register" element={<IndividualRegister />} />
              <Route path="corporate-login" element={<CorporateLogin />} />
              <Route path="corporate-register" element={<CorporateRegister />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="individual-pricing" element={<IndividualPricing />} />
            </Route>
          </Routes>
        </HelmetProvider>
      </LanguageProvider>
    </Router>

  );
};

export default App;
