import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { LanguageContext } from '../../../../lib/i18n/LanguageProvider';
import { useParams } from 'react-router-dom';

import topHeaderImage from '../../../../assets/images/banners/top_header.webp';
import twentyfourseven_1 from '../../../../assets/images/banners/twentyfourseven_1.png';

import { FaCheck } from 'react-icons/fa';

const TwentyFourSevenSupport: React.FC = () => {
    const { lang } = useParams();
    const context = useContext(LanguageContext);

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['twentyfourseven_service'] ? `${translations['twentyfourseven_service']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">
                <div
                    className="sub-header"
                    style={{
                        backgroundImage: `url(${topHeaderImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className="text-white mb-4">{translations['twentyfourseven_service']}</h3>
                                <p>{translations['twentyfourseven_description']}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 mb-4 mb-md-0">
                                <h2 className="mb-5">
                                    {translations['twentyfourseven_title_pre_primary']}
                                    {' '}
                                    <span className="text-primary">
                                        {translations['twentyfourseven_title_primary']}
                                    </span>
                                    {' '}
                                    {translations['twentyfourseven_title_post_primary']}
                                </h2>
                                <div className="row row-cols-1">
                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['twentyfourseven_individual_corporate']}</h6>
                                        </div>
                                        <p className="text-black">{translations['twentyfourseven_individual_corporate_desc']}</p>
                                    </div>

                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['twentyfourseven_phone_support']}</h6>
                                        </div>
                                        <p className="text-black">{translations['twentyfourseven_phone_support_desc']}</p>
                                    </div>

                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['twentyfourseven_whatsapp_support']}</h6>
                                        </div>
                                        <p className="text-black">{translations['twentyfourseven_whatsapp_support_desc']}</p>
                                    </div>

                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['twentyfourseven_expert_team']}</h6>
                                        </div>
                                        <p className="text-black">{translations['twentyfourseven_expert_team_desc']}</p>
                                    </div>

                                    <div className="col mb-4">
                                        <div className="d-flex align-items-center">
                                            <FaCheck size={30} className="text-primary m-1 pb-2" />
                                            <h6>{translations['twentyfourseven_technical_support']}</h6>
                                        </div>
                                        <p className="text-black">{translations['twentyfourseven_technical_support_desc']}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={twentyfourseven_1} alt="Banner" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default TwentyFourSevenSupport;
