import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { LanguageContext } from '../../../lib/i18n/LanguageProvider';
import countryCodes from '../../../assets/countryCodes.json';

import topHeaderImage from '../../../assets/images/banners/top_header.webp';

import contact_1 from '../../../assets/images/banners/contact_1.png';

import { FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';

const Contact: React.FC = () => {
    const context = useContext(LanguageContext);

    const [formData, setFormData] = useState({
        fullName: '',
        lastName: '',
        email: '',
        countryCode: countryCodes[0].phoneCode, // Varsayılan telefon kodu
        phone: '',
        message: '',
    });

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const { name, value, type } = e.target;

        if (type === 'checkbox') {
            setFormData((prevData) => ({
                ...prevData,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('Form Data Submitted:', formData);
        // Form verileri burada işlenecek
    };

    if (!context) {
        return null;
    }

    const { translations } = context;

    return (
        <div>
            <Helmet>
                <title>{translations['contact'] ? `${translations['contact']} - TechRSVP` : 'TechRSVP'}</title>
            </Helmet>
            <div className="body-bg landing-pages">

                <div
                    className="sub-header"
                    style={{
                        backgroundImage: `url(${topHeaderImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className="text-white mb-4">{translations['contact']}</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <div className="card rounded-1 contact-detail-card mb-lg-0">
                                    <div className="card-body text-center">
                                        <div className="services rounded-pill text-center mx-auto mb-4">
                                            <FaMapMarkerAlt size={40} className="text-primary" />
                                        </div>
                                        <h5 className="mt-4">{translations['contact_address']}</h5>
                                        <p className="mt-3">{translations['contact_address_details']}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card rounded-1 contact-detail-card mb-lg-0">
                                    <div className="card-body text-center">
                                        <div className="services rounded-pill text-center mx-auto mb-4">
                                            <FaEnvelope size={40} className="text-primary" />
                                        </div>
                                        <h5 className="mt-4">{translations['contact_email']}</h5>
                                        <p className="mt-3">{translations['contact_email_details']}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card rounded-1 contact-detail-card mb-lg-0">
                                    <div className="card-body text-center">
                                        <div className="services rounded-pill text-center mx-auto mb-4">
                                            <FaPhone size={40} className="text-primary" />
                                        </div>
                                        <h5 className="mt-4">{translations['contact_phone']}</h5>
                                        <p className="mt-3">{translations['contact_phone_details']}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding bg-secondary">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-2 text-uppercase text-white sub-title">{translations['contact_get_in_touch']}</div>
                                <h2 className="text-white heading-title">
                                    {translations['contact_have_a_question']} <br />
                                    <span className="text-primary">{translations['contact_write_to_us']}</span>
                                </h2>
                                <img src={contact_1} alt="Banner" className="img-fluid" />
                            </div>
                            <div className="col-lg-6 mt-5 mt-lg-0 contact-box">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="form-group col-lg-6 col-md-6">
                                            <label htmlFor="full-name" className="form-label text-white">{translations['contact_first_name']}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="full-name"
                                                name="fullName"
                                                placeholder={translations['contact_first_name']}
                                                value={formData.fullName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group col-lg-6 col-md-6">
                                            <label htmlFor="last-name" className="form-label text-white">{translations['contact_last_name']}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="last-name"
                                                name="lastName"
                                                placeholder={translations['contact_last_name']}
                                                value={formData.lastName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <label htmlFor="your-email" className="form-label text-white">{translations['contact_email_address']}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="your-email"
                                                name="email"
                                                placeholder={translations['contact_email_placeholder']}
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label htmlFor="country-code" className="form-label text-white">{translations['contact_phone_code']}</label>
                                            <select
                                                className="form-control"
                                                id="country-code"
                                                name="countryCode"
                                                value={formData.countryCode}
                                                onChange={handleChange}
                                            >
                                                {countryCodes.map((code) => (
                                                    <option key={code.phoneCode} value={code.phoneCode}>
                                                        {code.phoneCode} - {code.countryName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label htmlFor="phone-num" className="form-label text-white">{translations['contact_phone_number']}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="phone-num"
                                                name="phone"
                                                placeholder={translations['contact_phone_placeholder']}
                                                value={formData.phone}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group mb-4 col-lg-12">
                                            <label className="form-label text-white">{translations['contact_your_message']}</label>
                                            <textarea
                                                name="message"
                                                rows={10}
                                                className="form-control"
                                                placeholder={translations['contact_message_placeholder']}
                                                value={formData.message}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-lg-12">
                                            <button type="submit" className="btn btn-primary w-100">{translations['contact_send']}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Google Maps Embed */}
                <div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d765.1913018830121!2d32.8119165!3d39.9018888!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMznCsDU0JzA2LjgiTiAzMsKwNDgnNDcuMyJF!5e0!3m2!1str!2str!4v1728913238792!5m2!1str!2str"
                        height="450"
                        style={{ border: 0, width: '100%' }}
                        allowFullScreen={true}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default Contact;
